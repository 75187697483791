const dashboardStyle = theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
  },
  toolbar:
    theme.mixins.toolbar,
  card: {
    minWidth: 275,
    margin: 10
  },
  cardContent: {
    padding: 10
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    width: '100%',
    maxWidth: 500,
    fontSize: '13px'
  },
  pos: {
    marginBottom: 12,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    }
  }
});

export default dashboardStyle;