import {REDIRECT} from '../constants/action.types';

let initialState = {
    
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REDIRECT:
            return { ...state, redirectTo: null };
        default:
            return state;
    }
}