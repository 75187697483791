const GET_HELP_PROGRESS = "GET_HELP_PROGRESS";
const GET_HELP_SUCCESS = "GET_HELP_SUCCESS";
const GET_HELP_FAILURE = "GET_DASHBOARD_FAILURE";

export const getHelpProgress = () => {
    return {
        type: GET_HELP_PROGRESS
    }
};

export const getHelpSuccess = (helpData) => {
    return {
        type: GET_HELP_SUCCESS,
        helpData
    }
};

export const getHelpFailure = (errors) => {
    return {
        type: GET_HELP_FAILURE,
        errors
    }
};

export const getHelpData = () => (dispatch) => {
    // axios.get(apiConfig.API_HOST + apiConfig.API.getDirectorList + '5bdaac58d29f8223a87bdc49', {
    // }).then(function (response) {
    //     dispatch(getDirectorSuccess(response.data));
    // }).catch(function (error) {
    //     dispatch(getDirectorFailure(error));
    // });
}

let initialState = {
    helpData: [],
    errors: '',
    isInProgress: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_HELP_PROGRESS:
            return { ...state, isInProgress: true };
        case GET_HELP_SUCCESS:
            return { ...state, helpData: action.helpData, isInProgress: false };
        case GET_HELP_FAILURE:
            return { ...state, errors: action.errors, isInProgress: false };

        default:
            return state;
    }
}