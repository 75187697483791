import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

import PrivacyPolicy from '../components/pages/PrivacyPolicy';
import ContactUs from '../components/pages/ContactUs';
import TermsAndConditions from '../components/pages/TermsAndCondtions';
import Home from '../components/pages/Home';
import Faq from '../components/pages/Faq';

import GalapInfoView from '../components/pages/GalapInfoView';
import PaymentInfoView from '../components/pages/PaymentInfoView';
import VajawatInfoView from '../components/pages/VajawatInfoView';

import DirectorMessage from '../components/pages/DirectorMessage';
import Dashboard from '../containers/dashboard.container';
import Login from '../containers/login.container';
import Register from '../containers/register.container';
import Profile from '../containers/profile.container';
import ForgotPassword from '../containers/forgot.password.container';

const styles = theme => ({
    toolbar: theme.mixins.toolbar,
});

/**
 * Content rendering component
 */
class Content extends React.Component {

    render() {
        const { classes, theme } = this.props;

        return (
            <React.Fragment>
                <div className={classes.toolbar}></div>
                <Route exact path='/' component={Home} />
                <PrivateRoute path='/passbook' component={Dashboard} />
                <PrivateRoute path='/profile' component={Profile} />
                <PrivateRoute path='/galapinfo' component={GalapInfoView} />
                <PrivateRoute path='/paymentinfo' component={PaymentInfoView} />
                <PrivateRoute path='/vajawatinfo' component={VajawatInfoView} />
                <Route path='/login' component={Login} />
                <Route path='/register' component={Register} />
                <Route path='/contactus' component={ContactUs} />
                <Route path='/forgotpassword' component={ForgotPassword} />
                <Route path='/termsconditions' component={TermsAndConditions} />
                <Route path='/privacypolicy' component={PrivacyPolicy} />
                <Route path='/faq' component={Faq} />
                <Route path='/directormessage' component={DirectorMessage} />
            </React.Fragment>
        )
    }
}

Content.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Content);