import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

const CustomTableCell = withStyles(theme => ({
    head: {
        // backgroundColor: theme.palette.common.white,
        // color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

/**
 * Sabhsad factory wise galap and pyament information
 */
class DashboardGPSummary extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openGalapPaymentDetails: false,
            openGalapDetailsDialog: false,
            openPaymentDetailsDialog: false,
            openDeductionDetailsDialog: false,
            selectedGalapPaymentSummary: {},
            selectedGalapDetails: [],
            selectedPaymentDetails: [],
            selectedDeductionDetails: [],
            selectedGalapPaymentDetails: {},
            selectedSabhasadCode: '',
            selectedSabhasadDetails: {},
        }
    }

    getDate = (date) => {
        var monthNames = [
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sept", "Oct",
            "Nov", "Dec"
        ];

        let dateObj = new Date(date);

        var day = dateObj.getDate();
        var monthIndex = dateObj.getMonth();
        var year = dateObj.getFullYear();

        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }

    reducer = (accumulator, currentValue) => accumulator + currentValue;

    // Get sabhasad code wise total for same factory code
    groupBy = (objectArray, property) => {
        return objectArray.reduce(function (acc, obj) {
            var key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    /**
     * Get sabhasad wise summary for the same factory.
     */
    getSabhasadWiseSummaryForSameFactory = (selectedGalapDetails, selectedPaymentDetails, selectedDeductionDetails) => {

        selectedGalapDetails = selectedGalapDetails ? selectedGalapDetails : [];
        selectedPaymentDetails = selectedPaymentDetails ? selectedPaymentDetails : [];
        selectedDeductionDetails = selectedDeductionDetails ? selectedDeductionDetails : [];

        var sabhasadWiseGalapInfo = this.groupBy(selectedGalapDetails, 'sabhasadCode');
        var sabhasadWisePaymentInfo = this.groupBy(selectedPaymentDetails, 'sabhasadCode');
        var sabhasadWiseDeductionInfo = this.groupBy(selectedDeductionDetails, 'sabhasadCode');

        var sabhasadCodeGalapWise = [...new Set(selectedGalapDetails.map(item => item.sabhasadCode))]
        var sabhasadCodePaymentWise = [...new Set(selectedPaymentDetails.map(item => item.sabhasadCode))]

        var commonSabhasadCodes = [...new Set(sabhasadCodeGalapWise.concat(sabhasadCodePaymentWise))];

        let sabhasadWiseGalapPaymentSummary = [];

        commonSabhasadCodes.forEach(element => {

            let totalMetricTon = 0
            let totalAmountToPay = 0;
            let totalPaidAmount = 0;
            let totalBalanceAmount = 0;
            let totalDeductionAmount = 0;
            let frpRate = 0;
            let year = '';
            let sabhasadCode = '';
            let sabhasadName = '';
            let factoryName = '';
            let factoryCode = '';

            // Set value if the sabhasad code is avaliable in the galap info.
            if (sabhasadWiseGalapInfo[element] && sabhasadWiseGalapInfo[element].length > 0) {
                totalMetricTon = sabhasadWiseGalapInfo[element].map(info => info.metricTon).reduce(this.reducer);
                totalAmountToPay = sabhasadWiseGalapInfo[element].map(info => info.amountToPay).reduce(this.reducer);
                year = sabhasadWiseGalapInfo[element][0].year;
                frpRate = sabhasadWiseGalapInfo[element][0].frpRate;
                sabhasadCode = sabhasadWiseGalapInfo[element][0].sabhasadCode;
                sabhasadName = sabhasadWiseGalapInfo[element][0].sabhasadName;
                factoryCode = sabhasadWiseGalapInfo[element][0].factoryCode;
                factoryName = sabhasadWiseGalapInfo[element][0].factoryName;
            }

            // Set value if the factory code is avaliable in the payment info.
            if (sabhasadWisePaymentInfo[element] && sabhasadWisePaymentInfo[element].length > 0) {
                totalPaidAmount = sabhasadWisePaymentInfo[element].map(info => info.paidAmount).reduce(this.reducer);
            }

            if (sabhasadWiseDeductionInfo[element] && sabhasadWiseDeductionInfo[element].length > 0) {
                let deductionInfo = sabhasadWiseDeductionInfo[element];

                for (let index = 0; index < deductionInfo.length; index++) {
                    if (deductionInfo[index].deductionDetails.length > 0) {
                        totalDeductionAmount += deductionInfo[index].deductionDetails.
                            map(info => info.deductionAmount).reduce(this.reducer);
                    }
                }
            }

            totalBalanceAmount = totalAmountToPay - totalPaidAmount;
            totalBalanceAmount = totalBalanceAmount <= 0 ? 0 : totalBalanceAmount;

            sabhasadWiseGalapPaymentSummary.push({
                totalPaidAmount, totalMetricTon, totalAmountToPay,
                totalBalanceAmount, totalDeductionAmount, year, sabhasadCode, sabhasadName, factoryCode, factoryName, frpRate
            })
        });

        return sabhasadWiseGalapPaymentSummary;
    }

    handleClickOpen = () => {
        this.setState({ openGalapPaymentDetails: true });
    }

    handleClose = () => {
        this.setState({
            selectedGalapPaymentDetails: {},
            selectedGalapDetails: [],
            selectedPaymentDetails: [],
            openGalapPaymentDetails: false
        });
    }

    showGalapDetailsDialog = (row, galapInfo) => {

        this.props.gaEvent('Passbook', 'Show galap details', 'Show galap details click action');

        this.props.history.push({
            pathname: '/galapinfo',
            state: {
                selectedSabhasadDetails: row,
                selectedGalapDetails: galapInfo,
                classes: this.props.classes
            }
        });

        // this.setState({
        //     openGalapDetailsDialog: true, selectedSabhasadDetails: row,
        //     selectedGalapDetails: galapInfo
        // });
    }

    showDeductionDetailsDialog = (row, deductionInfo) => {

        this.props.gaEvent('Passbook', 'Show deduction details', 'Show deduction details click action');
        // this.setState({
        //     openDeductionDetailsDialog: true, selectedSabhasadDetails: row,
        //     selectedDeductionDetails: deductionInfo
        // });

        this.props.history.push({
            pathname: '/vajawatinfo',
            state: {
                selectedSabhasadDetails: row,
                selectedDeductionDetails: deductionInfo,
                classes: this.props.classes
            }
        });
    }

    showPaymentDetailsDialog = (row, paymentInfo) => {

        this.props.gaEvent('Passbook', 'Show payment details', 'Show payment details click action');
        // this.setState({
        //     openPaymentDetailsDialog: true, selectedSabhasadDetails: row,
        //     selectedPaymentDetails: paymentInfo
        // });

        this.props.history.push({
            pathname: '/paymentinfo',
            state: {
                selectedSabhasadDetails: row,
                selectedPaymentDetails: paymentInfo,
                classes: this.props.classes
            }
        });
    }

    hideGalapDetailsDialog = (row) => {
        this.setState({ openGalapDetailsDialog: false, selectedSabhasadDetails: '' })
    }

    hidePaymentDetailsDialog = (row) => {
        this.setState({ openPaymentDetailsDialog: false, selectedSabhasadDetails: '' })
    }

    hideDeductionDetailsDialog = (row) => {
        this.setState({ openDeductionDetailsDialog: false, selectedSabhasadDetails: '' })
    }

    render() {
        const { classes } = this.props;
        const { factoryCodeWiseGalapPaymentInfo, factoryCodes } = this.props;
        const currentComponent = this;

        const paymentDialog = (
            <Dialog
                fullScreen={true}
                open={this.state.openPaymentDetailsDialog}
                onClose={this.hidePaymentDetailsDialog}
                scroll='paper'>
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.hidePaymentDetailsDialog}
                            className={classes.menuButton}
                        >
                            <KeyboardBackspace />
                        </IconButton>

                        <Typography variant="h6" color="inherit" noWrap>
                            रक्कम माहिती
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.toolbar}></div>

                <DialogContent>
                    <Card style={{ marginTop: '10px' }}>
                        <CardContent style={{ padding: '5px' }}>
                            <Typography variant="caption" style={{ color: '#1976d2', fontSize: '15px' }}>
                                <b>{this.state.selectedSabhasadDetails.factoryName}</b>
                            </Typography>

                            <Grid container direction="row"
                                justify="space-between"
                                alignItems="flex-start" >
                                <Grid item>
                                    <Typography className={classes.title}
                                        variant="h6" style={{ fontSize: '15px' }}>
                                        <b>{this.state.selectedSabhasadDetails.sabhasadName}</b>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} variant="h6" style={{ fontSize: '15px' }} >
                                        सभासद क्र. <b>{this.state.selectedSabhasadDetails.sabhasadCode}</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr />

                            <Grid container direction="row"
                                justify="center"
                                alignItems="center"
                                style={{ backgroundColor: '#ffcdd2' }} >
                                <Grid item>
                                    <Typography className={classes.title}
                                        variant="h6" >
                                        <b>रक्कम माहिती</b>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <CustomTableCell className={classes.tableCell}>दिनांक</CustomTableCell>
                                        <CustomTableCell className={classes.tableCell}>बँक</CustomTableCell>
                                        <CustomTableCell className={classes.tableCell}>बँक अकाऊंट न.</CustomTableCell>
                                        <CustomTableCell className={classes.tableCell}>जमा रक्कम(रु)</CustomTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.selectedPaymentDetails ?
                                        this.state.selectedPaymentDetails.map(row => {
                                            if (row.sabhasadCode === currentComponent.state.selectedSabhasadDetails.sabhasadCode) {
                                                return (
                                                    <TableRow className={classes.row} key={row.id}>
                                                        <CustomTableCell className={classes.tableCell}>{this.getDate(row.paymentDate)}</CustomTableCell>
                                                        <CustomTableCell className={classes.tableCell}>{row.bankName}</CustomTableCell>
                                                        <CustomTableCell className={classes.tableCell}>{row.bankAccountNo}</CustomTableCell>
                                                        <CustomTableCell className={classes.tableCell}>{row.paidAmount}</CustomTableCell>
                                                    </TableRow>
                                                );
                                            }
                                        }) : <tr></tr>}

                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>

                </DialogContent>
            </Dialog>
        );

        const galapDialog = (
            <Dialog
                open={this.state.openGalapDetailsDialog}
                scroll='paper'
                fullScreen={true}
                onClose={this.hideGalapDetailsDialog}
            >
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.hideGalapDetailsDialog}
                            className={classes.menuButton}
                        >
                            <KeyboardBackspace />
                        </IconButton>

                        <Typography variant="h6" color="inherit" noWrap>
                            ऊस पुरवठा माहिती
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.toolbar}></div>

                <DialogContent>

                    <Card style={{ marginTop: '10px' }}>
                        <CardContent style={{ padding: '5px' }}>
                            <Typography variant="caption" style={{ color: '#1976d2', fontSize: '15px' }}>
                                <b>{this.state.selectedSabhasadDetails.factoryName}</b>
                            </Typography>

                            <Grid container direction="row"
                                justify="space-between"
                                alignItems="flex-start" >
                                <Grid item>
                                    <Typography className={classes.title}
                                        variant="h6" style={{ fontSize: '15px' }}>
                                        <b>{this.state.selectedSabhasadDetails.sabhasadName}</b>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} variant="h6"
                                        style={{ fontSize: '15px' }}>
                                        सभासद क्र. <b>{this.state.selectedSabhasadDetails.sabhasadCode}</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr />

                            <Grid container direction="row"
                                justify="center"
                                alignItems="center"
                                style={{ backgroundColor: '#ffcdd2' }} >
                                <Grid item>
                                    <Typography className={classes.title}
                                        variant="h6">
                                        <b>ऊस पुरवठा माहिती</b>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <CustomTableCell className={classes.tableCell}>दिनांक</CustomTableCell>
                                        <CustomTableCell className={classes.tableCell}>मे. टन</CustomTableCell>
                                        <CustomTableCell className={classes.tableCell} style={{ textAlign: 'right' }}>हंगाम FRP नुसार देय रक्कम(रु)</CustomTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.selectedGalapDetails ?
                                        this.state.selectedGalapDetails.map(row => {
                                            if (row.sabhasadCode === currentComponent.state.selectedSabhasadDetails.sabhasadCode) {
                                                return (
                                                    <TableRow className={classes.row} key={row.id}>
                                                        <CustomTableCell className={classes.tableCell}>{this.getDate(row.galapDate)}</CustomTableCell>
                                                        <CustomTableCell className={classes.tableCell}>{row.metricTon}</CustomTableCell>
                                                        <CustomTableCell className={classes.tableCell} style={{ textAlign: 'right' }} >{row.amountToPay}</CustomTableCell>
                                                    </TableRow>
                                                );
                                            }
                                        }) : <tr></tr>}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        );

        const deductionDialog = (
            <Dialog
                open={this.state.openDeductionDetailsDialog}
                scroll='paper'
                fullScreen={true}
                onClose={this.hideDeductionDetailsDialog}
            >
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.hideDeductionDetailsDialog}
                            className={classes.menuButton}
                        >
                            <KeyboardBackspace />
                        </IconButton>

                        <Typography variant="h6" color="inherit" noWrap>
                            वजावट माहिती
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.toolbar}></div>

                <DialogContent>

                    <Card style={{ marginTop: '10px' }}>
                        <CardContent style={{ padding: '5px' }}>
                            <Typography variant="caption" style={{ color: '#1976d2', fontSize: '15px' }}>
                                <b>{this.state.selectedSabhasadDetails.factoryName}</b>
                            </Typography>

                            <Grid container direction="row"
                                justify="space-between"
                                alignItems="flex-start" >
                                <Grid item>
                                    <Typography className={classes.title}
                                        variant="h6" style={{ fontSize: '15px' }}>
                                        <b>{this.state.selectedSabhasadDetails.sabhasadName}</b>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} variant="h6"
                                        style={{ fontSize: '15px' }}>
                                        सभासद क्र. <b>{this.state.selectedSabhasadDetails.sabhasadCode}</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr />

                            <Grid container direction="row"
                                justify="center"
                                alignItems="center"
                                style={{ backgroundColor: '#ffcdd2' }} >
                                <Grid item>
                                    <Typography className={classes.title}
                                        variant="h6">
                                        <b>वजावट माहिती</b>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <CustomTableCell className={classes.tableCell}>दिनांक</CustomTableCell>
                                        <CustomTableCell className={classes.tableCell}>वजावट</CustomTableCell>
                                        <CustomTableCell className={classes.tableCell} style={{ textAlign: 'right' }}>वजावट रक्कम(रु)</CustomTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.selectedDeductionDetails ?
                                        this.state.selectedDeductionDetails.map(row => {
                                            if (row.sabhasadCode === currentComponent.state.selectedSabhasadDetails.sabhasadCode) {
                                                return row.deductionDetails.map(deducDetails => {
                                                    return (
                                                        <TableRow className={classes.row} key={row.id}>
                                                            <CustomTableCell className={classes.tableCell}>{this.getDate(deducDetails.deductionDate)}</CustomTableCell>
                                                            <CustomTableCell className={classes.tableCell}>{deducDetails.deductionTitle}</CustomTableCell>
                                                            <CustomTableCell className={classes.tableCell} style={{ textAlign: 'right' }} >{deducDetails.deductionAmount}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                            // else{
                                            //     alert(row.sabhasadCode+' '+' '+ currentComponent.state.selectedDeductionDetails.sabhasadCode)
                                            // }
                                        }) : <tr></tr>}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        );

        return (
            <React.Fragment>
                <Grid container justify="center" direction="column">
                    {
                        factoryCodes.map((fCode, index) => {
                            return this.getSabhasadWiseSummaryForSameFactory(
                                factoryCodeWiseGalapPaymentInfo.factoryWiseGalapInfo[fCode],
                                factoryCodeWiseGalapPaymentInfo.factoryWisePaymentInfo[fCode],
                                factoryCodeWiseGalapPaymentInfo.factoryWiseDeductionInfo[fCode])
                                .map((row, index) => {
                                    return <Paper style={{ marginTop: '10px', padding: '5px' }}>
                                        <Typography variant="caption" style={{ color: '#1976d2', fontSize: '15px' }}>
                                            <b>{row.factoryName}</b>
                                        </Typography>

                                        <Grid container direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                        >
                                            <Grid item>
                                                <Typography className={classes.title}
                                                    variant="h6" style={{ fontSize: '15px' }} >
                                                    <b>{row.sabhasadName}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.title} variant="h6" style={{ fontSize: '15px' }}>
                                                    सभासद क्र. <b>{row.sabhasadCode}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <hr />
                                        <Grid container direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                            style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    एकूण ऊस पुरवठा (मे.टन):
                                        </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    <b>{Number(row.totalMetricTon).toFixed(2)}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                            style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    हंगाम FRP(रु):
                                        </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    <b>{Number(row.frpRate).toFixed(2)}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                            style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    हंगाम FRP प्रमाणे देय रक्कम(रु):
                                        </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    <b>{Number(row.totalAmountToPay).toFixed(2)}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                            style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    एकूण वजावट रक्कम(रु):
                                        </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    <b>{Number(row.totalDeductionAmount).toFixed(2)}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                            style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    एकूण जमा रक्कम(रु):
                                        </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography className={classes.title}
                                                    style={{
                                                        color: 'white', paddingLeft: '5px', paddingRight: '5px',
                                                        backgroundColor: '#388e3c'
                                                    }}
                                                    variant="h6" gutterBottom>
                                                    <b>{Number(row.totalPaidAmount).toFixed(2)}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                            style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>

                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    एकूण येणे बाकी रक्कम(रु):
                                        </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6"
                                                    style={{
                                                        color: 'white', paddingLeft: '5px', paddingRight: '5px',
                                                        backgroundColor: '#d32f2f'
                                                    }}
                                                    gutterBottom>
                                                    <b>{Number(row.totalBalanceAmount).toFixed(2)}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container direction="row"
                                            justify="space-between"
                                            alignItems="flex-start"
                                            style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    हंगाम FRP पेक्षा अतिरिक्त रक्कम(रु):
                                        </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography className={classes.title} variant="h6" gutterBottom>
                                                    <b>{row.totalPaidAmount - row.totalAmountToPay <= 0 ? 0 : Number(row.totalPaidAmount - row.totalAmountToPay).toFixed(2)}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <hr />

                                        <Grid container direction='row'
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item style={{ marginTop: '5px' }}>
                                                <Button
                                                    style={{
                                                        backgroundColor: '#ffecb3', textTransform: 'none',
                                                        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                                                        padding: '0px 4px'
                                                    }}
                                                    onClick={(e) => { this.showGalapDetailsDialog(row, factoryCodeWiseGalapPaymentInfo.factoryWiseGalapInfo[fCode]) }}>
                                                    ऊस पुरवठा माहिती
                                                </Button>
                                            </Grid>

                                            <Grid item style={{ marginTop: '5px' }}>
                                                <Button
                                                    style={{
                                                        backgroundColor: '#ffebee', textTransform: 'none',
                                                        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                                                        padding: '0px 4px'
                                                    }}
                                                    onClick={(e) => { this.showPaymentDetailsDialog(row, factoryCodeWiseGalapPaymentInfo.factoryWisePaymentInfo[fCode]) }}>
                                                    Payment माहिती
                                                </Button>
                                            </Grid>

                                            <Grid item style={{ marginTop: '5px' }}>
                                                <Button
                                                    style={{
                                                        backgroundColor: '#e8eaf6', textTransform: 'none',
                                                        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                                                        padding: '0px 4px'
                                                    }}
                                                    onClick={(e) => { this.showDeductionDetailsDialog(row, factoryCodeWiseGalapPaymentInfo.factoryWiseDeductionInfo[fCode]) }}>
                                                    वजावट माहिती
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </Paper>
                                })
                        })
                    }
                </Grid>

                {paymentDialog}
                {galapDialog}
                {deductionDialog}
            </React.Fragment>
        )
    }
}

export default DashboardGPSummary;