import { getAxiosInstance } from '../configs/axios.config';
import { REGISTER, SET_MESSAGE } from '../constants/action.types';

const FORGOT_PASSWORD_PROGRESS = 'FORGOT_PASSWORD_PROGRESS';
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
const RESET_FORGOT_PASSWORD_STATE = 'RESET_FORGOT_PASSWORD_STATE';

const FORGOT_PASSWORD_SEND_OTP_PROGRESS = 'FORGOT_PASSWORD_SEND_OTP_PROGRESS';
const FORGOT_PASSWORD_SEND_OTP_SUCCESS = 'FORGOT_PASSWORD_SEND_OTP_SUCCESS';
const FORGOT_PASSWORD_SEND_OTP_FAILURE = 'FORGOT_PASSWORD_SEND_OTP_FAILURE';

const FORGOT_PASSWORD_VERIFY_OTP_PROGRESS = 'FORGOT_PASSWORD_VERIFY_OTP_PROGRESS';
const FORGOT_PASSWORD_VERIFY_OTP_SUCCESS = 'FORGOT_PASSWORD_VERIFY_OTP_SUCCESS';
const FORGOT_PASSWORD_VERIFY_OTP_FAILURE = 'FORGOT_PASSWORD_VERIFY_OTP_FAILURE';

export const sendOtpProgress = () => { return { type: FORGOT_PASSWORD_SEND_OTP_PROGRESS } };

export const sendOtpSuccess = () => { return { type: FORGOT_PASSWORD_SEND_OTP_SUCCESS } };

export const sendOtpFailure = (message) => { return { type: FORGOT_PASSWORD_SEND_OTP_FAILURE } };

export const verifyOtpProgress = () => { return { type: FORGOT_PASSWORD_VERIFY_OTP_PROGRESS } };

export const verifyOtpSuccess = () => { return { type: FORGOT_PASSWORD_VERIFY_OTP_SUCCESS } };

export const verifyOtpFailure = (message) => { return { type: FORGOT_PASSWORD_VERIFY_OTP_FAILURE } };

export const forgotPasswordProgress = () => { return { type: FORGOT_PASSWORD_PROGRESS } };

export const forgotPasswordSuccess = (data) => { return { type: FORGOT_PASSWORD_SUCCESS } };

export const forgotPasswordFailure = (message) => { return { type: FORGOT_PASSWORD_FAILURE } };

export const sendOtp = (mobileNo) => (dispatch) => {

    if(!navigator.onLine)
    {
        dispatch({ type: SET_MESSAGE, message: 'कृपया आपले इंटरनेट जोडणी तपासा' });
        return;
    }

    dispatch(sendOtpProgress());

    let axios = getAxiosInstance();

    axios.post('forgotpasswordsendotp/', { mobileNo })
        .then(function (response) {
            dispatch(sendOtpSuccess(response.data));
            dispatch({ type: SET_MESSAGE, message: "OTP आपल्या रजिस्टर मोबाईल न. ला पाठविला आहे" })
        }).catch(function (error) {
            if (error && error.response
                && error.response.data &&
                error.response.data.message) {
                dispatch({ type: SET_MESSAGE, message: error.response.data.message });
            }
            else { dispatch({ type: SET_MESSAGE, message: 'Server Error' }) }
        });
}

export const verifyOtp = (mobileNo, otp) => (dispatch) => {

    if(!navigator.onLine)
    {
        dispatch({ type: SET_MESSAGE, message: 'कृपया आपले इंटरनेट जोडणी तपासा' });
        return;
    }

    dispatch(verifyOtpProgress());

    let axios = getAxiosInstance();

    let action = "FORGOT_PASSWORD";
    axios.post('verifyotp/', { mobileNo, otp, action })
        .then(function (response) {
            if (response && response.status === 200) {
                dispatch(verifyOtpSuccess(response.data));
            }

            dispatch({ type: SET_MESSAGE, message: response.data.message })
        }).catch(function (error) {
            if (error && error.response
                && error.response.data &&
                error.response.data.message) {
                dispatch({ type: SET_MESSAGE, message: error.response.data.message });
            }
            else { dispatch({ type: SET_MESSAGE, message: 'Server Error' }) }
        });
}

export const forgotPasswordAction = (userData) => (dispatch) => {

    if(!navigator.onLine)
    {
        dispatch({ type: SET_MESSAGE, message: 'कृपया आपले इंटरनेट जोडणी तपासा' });
        return;
    }
    
    dispatch(forgotPasswordProgress());
   
    let axios = getAxiosInstance();

    axios.post('forgotpassword/', userData)
        .then(function (response) {
            dispatch(forgotPasswordSuccess(response.data));
            dispatch({ type: SET_MESSAGE, message: response.data.message })
            // Redirect to login page after forgot password.
           setTimeout(() => { dispatch({ type: REGISTER }) }, 1000);
        }).catch(function (error) {
            if (error && error.response
                && error.response.data &&
                error.response.data.message) {
                dispatch({ type: SET_MESSAGE, message: error.response.data.message });
            }
            else { dispatch({ type: SET_MESSAGE, message: 'Server Error' }) }
        });
}

export const resetForgotPasswordState = () => (dispatch) => {
    dispatch({ type: RESET_FORGOT_PASSWORD_STATE });
} 

let initialState = {
    otpSend: false,
    mobileVerify: false,
    otpVerify: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_SEND_OTP_PROGRESS:
            return { ...state, isInProgress: true };
        case FORGOT_PASSWORD_SEND_OTP_SUCCESS:
            return { ...state, isInProgress: false, otpSend: true };
        case FORGOT_PASSWORD_SEND_OTP_FAILURE:
            return { ...state, errors: action.errors, isInProgress: false };

        case FORGOT_PASSWORD_VERIFY_OTP_PROGRESS:
            return { ...state, isInProgress: true };
        case FORGOT_PASSWORD_VERIFY_OTP_SUCCESS:
            return { ...state, isInProgress: false, mobileVerify: true };
        case FORGOT_PASSWORD_VERIFY_OTP_FAILURE:
            return { ...state, errors: action.errors, isInProgress: false };

        case FORGOT_PASSWORD_PROGRESS:
            return { ...state, isInProgress: true };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state, isInProgress: false, otpSend: false,
                mobileVerify: false,
                otpVerify: true
            };
        case RESET_FORGOT_PASSWORD_STATE:
            return { ...state, otpSend: false, mobileVerify: false };

        case FORGOT_PASSWORD_FAILURE:
            return { ...state, errors: action.errors, isInProgress: false };

        default:
            return state;
    }
}