import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Login from '../components/pages/Login';
import * as actions from '../reducers/login.reducer';

const mapStateToProps = (state) => {
    return {
        isInProgress: state.login.isInProgress,
        user: state.login.user,
        // redirectTo: state.login.redirectTo
    };
};


export default withRouter(connect(mapStateToProps, actions)(Login));
