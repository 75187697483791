import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

/**
 *  Total of galap and payment from sabhasad all factories
 */
class DashboardSummary extends React.Component {
    render() {
        const { classes } = this.props;
        const { summary, year } = this.props;

        return (
            <Grid item>
                <Paper style={{ marginTop: '10px', padding: '5px' }}>
                    <Typography variant="caption" style={{ color: '#1976d2', fontSize: '15px' }} gutterBottom>
                        <b>वर्ष ({year}-{(year + 1).toString().substr(-2)}) संक्षिप्त माहिती</b>
                    </Typography>
                    <hr />
                    <Grid container direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                        <Grid item >
                            <Typography className={classes.title} variant="h6" gutterBottom>
                                एकूण ऊस पुरवठा (मे.टन):
                        </Typography>
                        </Grid>
                        <Grid item >
                            <Typography className={classes.title} variant="h6" gutterBottom>
                                <b>{Number(summary.totalMetricTon).toFixed(2)}</b>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                        <Grid item >
                            <Typography className={classes.title} variant="h6" gutterBottom>
                                हंगाम FRP प्रमाणे देय रक्कम(रु):
                        </Typography>
                        </Grid>
                        <Grid item >
                            <Typography className={classes.title} variant="h6" gutterBottom>
                                <b>{Number(summary.totalAmountToPay).toFixed(2)}</b>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                        <Grid item >
                            <Typography className={classes.title} variant="h6" gutterBottom>
                                एकूण वजावट रक्कम(रु):
                        </Typography>
                        </Grid>
                        <Grid item >
                            <Typography className={classes.title} variant="h6" gutterBottom>
                                <b>{Number(summary.totalDeductionAmount).toFixed(2)}</b>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        style={{ backgroundColor: '#e1f5fe', paddingLeft: '5px', paddingRight: '5px' }}>
                        <Grid item >
                            <Typography className={classes.title} variant="h6" gutterBottom>
                                एकूण जमा रक्कम(रु):
                        </Typography>
                        </Grid>
                        <Grid item >
                            <Typography className={classes.title} variant="h6" gutterBottom>
                                <b>{Number(summary.totalPaidAmount).toFixed(2)}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>);
    }
}

export default DashboardSummary;