import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Register from '../components/pages/Register';
import * as actions from '../reducers/register.reducer';

const mapStateToProps = (state) => {
    return {
        isInProgress: state.register.isInProgress,
        mobileVerify: state.register.mobileVerify,
        otpSend: state.register.otpSend,
        // redirectTo: state.register.redirectTo
    };
};


export default withRouter(connect(mapStateToProps, actions)(Register));
