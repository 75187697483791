import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

let openSnackbarFn;

class Notifier extends React.Component {
    // 1. set the Notifier's initial state
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            message: '',
        };
    }

    componentDidMount() {
        openSnackbarFn = this.openSnackbar;
    }

    // 2. define a function to open Snackbar and show a message
    openSnackbar = (message) => {
        this.setState({
            open: true,
            message,
        });
    };

    // 3. define a function to close Snackbar when a user clicks away
    handleSnackbarClose = () => {
        this.setState({
            open: false,
            message: '',
        });

        this.props.resetMessage();
    };


    render() {
        // 4. show a message to users
        const message = (
            <span
                id="snackbar-message-id"
                dangerouslySetInnerHTML={{ __html: this.state.message }} />
        );

        return (
            <Snackbar
                open={this.state.open}
                autoHideDuration={3000}
                onClose={this.handleSnackbarClose}
                //anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

                message={message}


                SnackbarContentProps={{
                    'aria-describedby': 'snackbar-message-id',
                }}
            />
        );
    }
}

export function openSnackbar(message) {
    openSnackbarFn(message);
}

export default Notifier;