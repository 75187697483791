const directorMessageStyle = theme => ({
    card: {
        minWidth: 275,
        margin: '10px'
      },
      media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'cover',
      },
      avatar:{height: '50px', width: '50px'},
      img: {
        marginTop:'10px',
        boxShadow:'0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
      },
      signImg: {
        width: '20%',
        height:'20px',
        margin:'10px',
        float:'right'
      }
});

export default directorMessageStyle;