import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import Content from '../components/Content';
import Home from '@material-ui/icons/Home';
import Receipt from '@material-ui/icons/Receipt';
import Call from '@material-ui/icons/Call';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import Message from '@material-ui/icons/Message';
import Description from '@material-ui/icons/Description';
import Https from '@material-ui/icons/Https';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Notifier, { openSnackbar } from './reusables/Notifier';
import headerStyle from '../assets/jss/components/pages/headerStyle';

import ReactGA from 'react-ga';

//const drawerWidth = 240;

const routes = [
    { path: '/', text: 'होम', icon: (<Home />), barTitle: 'ऊस उत्पादक शेतकरी' },
    { path: '/passbook', text: 'ऊस पासबुक', icon: (<Receipt />), private: true, barTitle: 'पासबुक' },
    // { path: '/contactus', text: 'संपर्क', icon: (<Call />), private: false, barTitle: 'संपर्क' },
    { path: '/directormessage', text: 'शेतकऱ्यासाठी संदेश', icon: (<Message />), private: false, barTitle: 'Director Message' },
    // { path: '/privacypolicy', text: 'गोपनीयता', icon: (<Https />), private: false, barTitle: 'गोपनीयता' },
    { path: '/faq', text: 'FAQ', icon: (<QuestionAnswer />), private: false, barTitle: 'FAQ' },
    { path: '/termsconditions', text: 'Terms & Conditions', icon: (<Description />), private: false, barTitle: 'नियम व अटी' },
    // { path: '/aboutus', text: 'आमच्या विषयी', icon: (<Info />) },
    // { path: '/directors', text: 'संचालक', icon: (<SupervisedUserCircle />) },
    // { path: '/gallery', text: 'गॅलरी', icon: (<PermMedia />) }
];

class Header extends React.Component {
    state = {
        mobileOpen: false,
        selectedMenuIndex: 1,
        anchorEl: null,
    };

    gaEvent = (category, event, label) => {
        ReactGA.event({
            category: category,
            action: event,
            label: JSON.stringify(label)
        });
    }

    handleMenuListItemClick = (event, index) => {
        this.setState({ selectedMenuIndex: index });
    };

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    logOut = () => {

        this.gaEvent('User', 'Logout', this.state.registerForm);
        this.props.onLogout();
        this.props.history.push('/');
    }

    getProfile = () => {
        this.gaEvent('User', 'Get Profile', 'Get profile click action');
        this.props.history.push('/profile');
    }

    logIn = () => {
        this.gaEvent('User', 'Login', 'Login button click action');
        this.props.history.push('/login');
    }

    activeRoute(routeName) {
        return this.props.location.pathname === routeName ? true : false;
    }

    showNotifier = (message) => {
        openSnackbar(message);
    }

    getAppBarTitle = (routeName) => {
        switch (routeName) {
            case "/login":
                return 'ऊस उत्पादक शेतकरी';
            case "/register":
                return 'शेतकरी नोंदणी';
            case "/passbook":
                return 'ऊस पासबुक';
            case "/profile":
                return 'प्रोफाईल';
            case "/contactus":
                return 'संपर्क';
            case "/faq":
                return 'FAQ';
            case "/termsconditions":
                return "Terms & Conditions";
            case "/directormessage":
                return 'शेतकऱ्यासाठी संदेश';
            default:
                return 'ऊस उत्पादक शेतकरी';
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.redirectTo) {
            this.props.history.push(nextProps.redirectTo);
            this.props.onRedirect();
        }
    }

    redirectTo = (path) => {

        if (path === '/index.html') {
            this.props.history.push('/');
        }
    }

    render() {
        const { classes, theme } = this.props;
        const { isAuthenticated } = this.props;
        const { anchorEl } = this.state;
        const color = "blue";
        const open = Boolean(anchorEl);

        this.redirectTo(this.props.location.pathname);

        const list = (<React.Fragment>
            <List className={classes.list}>
                {routes.map((route, index) => {
                    var listItemClasses;
                    var activePro = " ";
                    listItemClasses = classNames({
                        [" " + classes[color]]: this.activeRoute(route.path)
                    });

                    const whiteFontClasses = classNames({
                        [" " + classes.whiteFont]: this.activeRoute(route.path)
                    });

                    return (
                        <NavLink
                            to={route.path}
                            className={activePro + classes.item}
                            onClick={this.handleDrawerToggle}
                            activeClassName="active">
                            <ListItem
                                button
                                className={classes.itemLink + listItemClasses}
                            >
                                <ListItemIcon className={classes.itemIcon}>
                                    {route.icon}
                                </ListItemIcon>
                                <ListItemText primary={route.text}
                                    className={classes.itemText + whiteFontClasses} />
                            </ListItem>
                        </NavLink>)
                })}
                <hr />
            </List>

          
          <Typography align="center" component="h5" variant="caption">
                {'Version ' + process.env.REACT_APP_VERSION}
                </Typography>

             <Typography align="center" component="h4" variant="caption">
                Deavnet Solutions
            </Typography>
        </React.Fragment>
        );

        const drawer = (
            <React.Fragment>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={this.handleDrawerToggle}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <div className={classes.sidebarWrapper}>
                    {list}

                </div>
            </React.Fragment>
        );

        return (<div className={classes.root}>
            <AppBar position="fixed"
                className={classNames(classes.appBar, {
                    [classes.appBarShift]: this.state.open,
                })}>
                < CssBaseline />
                <Toolbar>
                    <IconButton color="inherit"
                        aria-label="Menu"
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.grow}
                        style={{ fontSize: '20px' }}>
                        {this.getAppBarTitle(this.props.location.pathname)}
                    </Typography>

                    {isAuthenticated ? (
                        <div>
                            <IconButton
                                aria-owns={true ? 'menu-appbar' : undefined}
                                aria-haspopup="true"
                                color="inherit"
                                onClick={this.handleMenu}
                            >
                                <AccountCircle />
                            </IconButton>
                            <ClickAwayListener onClickAway={this.handleMenuClose}>
                                <Paper>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={this.handleMenuClose}
                                    >
                                        <MenuItem onClick={this.getProfile} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                            <Typography variant="caption">
                                                प्रोफाईल
                                        </Typography>
                                        </MenuItem>
                                        <MenuItem onClick={this.logOut} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                            <Typography variant="caption">
                                                Logout
                                        </Typography>
                                        </MenuItem>
                                    </Menu>
                                </Paper>
                            </ClickAwayListener>
                        </div>
                    ) : (<div>
                        <Button variant="outlined" className={classes.button}
                            style={{ color: 'white', textTransform: 'none' }}
                            onClick={this.logIn} >
                            Login
                  </Button></div>)}
                </Toolbar>
            </AppBar>

            <nav className={classes.drawer}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={this.props.container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={this.state.mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >

                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>

            {/* Content Rendering */}
            <main className={classes.content}>
                <Content headerProps={this.props} />
            </main>

            <Notifier resetMessage={this.props.resetMessage} />

            {this.props.message && this.showNotifier(this.props.message)}
        </div>);
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(headerStyle, { withTheme: true })(Header);
