import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Profile from '../components/pages/Profile';
import * as actions from '../reducers/profile.reducer';

const mapStateToProps = (state) => {
    return {
        isInProgress: state.profile.isInProgress,
        user: state.profile.user
    };
};


export default withRouter(connect(mapStateToProps, actions)(Profile));
