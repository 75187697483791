import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';

import PersonOutline from '@material-ui/icons/PersonOutlined';
import Info from '@material-ui/icons/InfoOutlined';
import MobileIcon from '@material-ui/icons/MobileFriendlyOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import ReactGA from 'react-ga';

import { authDetails } from '../../auth/authdetails';

import withStyles from '@material-ui/core/styles/withStyles';
import loginStyle from '../../assets/jss/components/pages/loginStyle';

/**
 * Register component
 */
class Register extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            otpVerifForm: { otp: '' },
            mobileVerifyForm: { mobileNo: '' },
            registerForm: {
                firstName: '',
                middleName: '',
                lastName: '',
                password: '',
                confirmPassword: ''
            },
            submitted: false,
            showTermsAndCondtions: false,
            showPassword: false,
            showConfirmPassword: false
        }
    }

    gaEvent = (category,event,label)=>
    {
        ReactGA.event({
            category: category,
            action: event,
            label: JSON.stringify(label)            
          });
    }

    handleRegisterChange = (event) => {
        const { registerForm } = this.state;
        registerForm[event.target.name] = event.target.value;
        this.setState({ registerForm });
    }

    handleOtpVerifyChange = (event) => {
        const { otpVerifForm } = this.state;
        otpVerifForm[event.target.name] = event.target.value;
        this.setState({ otpVerifForm });
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleClickShowConfirmPassword = () => {
        this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    };

    handleMobileVerifyChange = (event) => {
        const { mobileVerifyForm } = this.state;
        mobileVerifyForm[event.target.name] = event.target.value;
        this.setState({ mobileVerifyForm });
    }

    handleCheckChange = (name) => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleOtpSubmit = () => {
        this.gaEvent('User','Register User, Verify OTP',this.state.mobileVerifyForm.mobileNo);
        this.props.verifyOtp(this.state.mobileVerifyForm.mobileNo, this.state.otpVerifForm.otp);
    }

    handleSendOtpSubmit = () => {

        this.gaEvent('User','Register-Send OTP',this.state.mobileVerifyForm.mobileNo);
        this.props.sendOtp(this.state.mobileVerifyForm.mobileNo);
    }

    handleRegisterSubmit = () => {
        
        this.gaEvent('User','Register User', this.state.mobileVerifyForm.mobileNo);
        let user = {};
        user.firstName = this.state.registerForm.firstName;
        user.middleName = this.state.registerForm.middleName;
        user.lastName = this.state.registerForm.lastName;
        user.mobileNo = this.state.mobileVerifyForm.mobileNo;
        user.password = this.state.registerForm.password;

        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });

        this.props.register(user);
    }

    onCancel = () => {
        this.props.history.push('/login');
        this.props.resetRegisterState();
    }

    showTermsAndCondtions = () => {
        this.setState({ showTermsAndCondtions: true });
    }

    hideTermsAndConditions = () => {
        this.setState({ showTermsAndCondtions: false });
    }

    componentWillMount() {

        if(authDetails())
        {
            this.props.history.push('/');
            return;
        }

        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.registerForm.password) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount()
    {
        this.props.resetRegisterState();
    }

    componentDidMount()
    {
    }

    render() {

        const { classes } = this.props;
        const { registerForm, mobileVerifyForm, otpVerifForm, submitted, showTermsAndCondtions } = this.state;
        const { mobileVerify, otpSend } = this.props;

        const termsAndConditionDialog = (
            <Dialog
                open={showTermsAndCondtions}
                onClose={this.hideTermsAndConditions}
                scroll='paper'
            > 
            
           <DialogTitle id="tc">नियम आणि अटी</DialogTitle>
          <DialogContent>
              <DialogContentText>
              Help protect your website and its users with clear and fair website terms and conditions.
                         These terms and conditions for a website set out key issues such as acceptable use, privacy, cookies, registration and passwords, intellectual property, links to other sites, termination and disclaimers of responsibility. Terms and conditions are used and necessary to protect a website owner from liability of a user relying on the information or the goods provided from the site then suffering a loss.
    
                        Making your own terms and conditions for your website is hard,
                        not impossible, to do. It can take a few hours to few days for a person with no legal background to make. But worry no more; we are here to help you out.
    
                </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.hideTermsAndConditions} color="primary">
              Close
            </Button>
          </DialogActions>
              
            </Dialog>);

        return (
            <main className={classes.main}>
                <CssBaseline />

                {mobileVerify ?

                    // <Slide direction="down" in={mobileVerify} mountOnEnter unmountOnExit>
                        <Paper className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <PersonOutline />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    माझी माहिती
                                </Typography>
                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleRegisterSubmit}
                                className={classes.form}
                            >                              
                                <TextValidator
                                    label="पहिले नाव \ First Name"
                                    onChange={this.handleRegisterChange}
                                    fullWidth
                                    name="firstName"
                                    value={registerForm.firstName}
                                    // validators={['required']}
                                    // errorMessages={['पहिले नाव भरा']}
                                />

                                <TextValidator
                                    label="मधले नाव \ Middle Name"
                                    onChange={this.handleRegisterChange}
                                    fullWidth
                                    name="middleName"
                                    value={registerForm.middleName}
                                    // validators={['required']}
                                    // errorMessages={['मधले नाव भरा']}
                                />

                                <TextValidator
                                    label="आडनाव \ Last Name"
                                    onChange={this.handleRegisterChange}
                                    fullWidth
                                    name="lastName"
                                    value={registerForm.lastName}
                                    // validators={['required']}
                                    // errorMessages={['आडनाव भरा']}
                                />
                                <TextValidator
                                    label="पासवर्ड \ Password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    onChange={this.handleRegisterChange}
                                    name="password"
                                    value={registerForm.password}
                                    validators={['required']}
                                    errorMessages={['पासवर्ड भरा']}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />

                                <TextValidator
                                    label="पासवर्डची पुष्टी करा \ Re-type Password"
                                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                                    onChange={this.handleRegisterChange}
                                    name="confirmPassword"
                                    value={registerForm.confirmPassword}
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['पासवर्ड match होत नाही', 'पासवर्ड भरा']}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowConfirmPassword}
                                            >
                                                {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />

                                 <Grid container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center" style={{marginTop:'10px'}}>
                                
                                    <Grid item style={{width:'225px'}}>
                                    <FormControlLabel
                                    control={
                                           <Checkbox
                                            checked={this.state.checkedB}
                                            onChange={this.handleCheckChange('checkedB')}
                                            value="checkedB"
                                            color="primary"
                                            required
                                        />
                                    }
                                  label="मोबाईल अँप वापराबाबतच्या नियम व अटी मला मान्य आहेत"
                                />
                                </Grid>
                                <Grid item >
                                <FormControlLabel
                                    control={
                                        <Info color="primary" style={{fontSize:'20px'}} onClick={this.showTermsAndCondtions}/>
                                    }
                                />

                                </Grid>    
                                
                                    </Grid>
                              
                                <Grid container
                                    direction="row"
                                    justify="center"
                                    alignItems="center">
                                
                                    <Grid item>
                                        <Button
                                            disabled={submitted}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}>
                                            नोंदणी करा

                                        </Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </Paper>
                    // </Slide> 
                    :

                    otpSend ?
                        // <Slide direction="down" in={otpSend} mountOnEnter unmountOnExit>
                            <Paper className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                    <MobileIcon />
                                </Avatar>
                                <Typography variant="h5">
                                   शेतकरी नोंदणी
                                </Typography>
                                <Typography variant="caption">
                                   मोबाईल वरती SMS द्वारे पाठवलेला OTP टाका
                                </Typography>
                                <Typography variant="caption" style={{color:'red'}}>
                                   OTP वैध कालावधी १२० सेकंद
                                </Typography>
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleOtpSubmit}
                                    className={classes.form}>

                                    <TextValidator
                                        label="Onetime Password(OTP)"
                                        onChange={this.handleOtpVerifyChange}
                                        fullWidth
                                        name="otp"
                                        value={otpVerifForm.otp}
                                        validators={['required']}
                                        errorMessages={['Onetime Password(OTP) टाका']}
                                    />
                                    <Grid container
                                        direction="column"
                                        justify="center"
                                        alignItems="center">
                                         {/* <Grid item>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                                onClick={this.onCancel}
                                            >
                                                रद्द करा
                                            </Button>
                                        </Grid> */}
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.submit}
                                            >
                                            Verify
                                            </Button>
                                        </Grid>   

                                                <Grid item style={{ marginTop: '20px' }}>
                                            <Typography variant="caption">
                                                OTP परत पाठविण्यासाठी येथे Click करा
                                            </Typography>

                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.submit}
                                                style={{ textTransform: 'none', marginTop: '0px' }}
                                                onClick={this.handleSendOtpSubmit}
                                            >
                                                Resend OTP
                                            </Button>
                                        </Grid>                                    
                                    </Grid>
                                </ValidatorForm>
                            </Paper>
                        // </Slide>
                        :
                        // <Slide direction="down" in={!otpSend} mountOnEnter unmountOnExit>
                            <Paper className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <MobileIcon />
                                </Avatar>
                                <Typography variant="h5">
                                   शेतकरी नोंदणी
                                </Typography>
                                <Typography variant="caption">
                                   आपण साखर कारखान्यांमध्ये नोंद केलेला मोबाईल नंबर टाका
                                </Typography>
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSendOtpSubmit}
                                    className={classes.form}>

                                    <TextValidator
                                        label="मोबाईल नंबर \ Mobile Number"
                                        onChange={this.handleMobileVerifyChange}
                                        fullWidth
                                        name="mobileNo"
                                        type='number'
                                        value={mobileVerifyForm.mobileNo}
                                        validators={['required']}
                                        errorMessages={['आपला मोबाईल नंबर टाका']}
                                    />
                                    <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="center">

                                        {/* <Grid item>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                                onClick={this.onCancel}
                                            >
                                                रद्द करा
                                        </Button>
                                        </Grid> */}
                                    
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.submit}
                                                style={{marginTop:'15px', textTransform:'none'}}>
                                                Verify
                                            </Button>

                                        </Grid>
                                    </Grid>
                                    <Typography variant="caption" style={{color:'red', marginTop:'10px'}}>
                                     मोबाईल नंबर ची पडताळणी OTP द्वारे होईल.
                                    </Typography>
                                    <Typography variant="caption" style={{color:'red'}}>
                                     OTP आपणास त्वरित SMS द्वारे पाठवला जाईल
                                    </Typography>
                                </ValidatorForm>
                            </Paper>
                        // </Slide>
                        }
                {termsAndConditionDialog}
            </main >


        );

    }
}

Register.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(loginStyle)(Login);

export default withStyles(loginStyle, { withTheme: true })((Register));
