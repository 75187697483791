import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import directorMessageStyle from '../../assets/jss/components/pages/directorMessageStyle';
import dm1 from '../../assets/img/dm1.jpg';
import dm2 from '../../assets/img/dm2.png';
import signature from '../../assets/img/dmsign1.png';

const directorMessages = [
    {
        imgPath: dm1,
        name: 'श्री.शेखर गायकवाड (भा.प्र.से)',
        designation: 'साखर आयुक्त, साखर आयुक्तालय महाराष्ट्र राज्य, पुणे',
        sign: signature,
        message: 'महाराष्ट्र राज्य साखर आयुक्तालय, महाराष्ट्र शासन, यांनी महाराष्ट्र राज्यातील ऊस उत्पादक शेतकऱ्यांसाठी विविध उपयुक्त'+
        'माहिती सुलभरित्या उपलब्धकरून देण्याचे धोरण केले आहे. या धोरणाला अनुसरून महाराष्ट्र राज्यातील ऊस उत्पादक'+
        'शेतकरी या मोबाईल अँप द्वारे शेतकऱ्याच्या ऊस गाळप बाबतची माहिती, FRP नुसार होणारी देय रक्कम व रक्कम'+
        'अदायगीची माहिती शेतकऱ्यांना लगेच उपलब्ध असेल.'
    },
    {
        imgPath: dm2,
        name: 'श्री.ज्ञानदेव बाळू मुकणे',
        designation: 'संचालक अर्थ,साखर आयुक्तालय महाराष्ट्र राज्य, पुणे',
        sign: signature,
        message: 'महाराष्ट्र राज्य साखर आयुक्तालय, महाराष्ट्र शासन, यांनी महाराष्ट्र राज्यातील ऊस उत्पादक शेतकऱ्यांसाठी विविध उपयुक्त'+
        'माहिती सुलभरित्या उपलब्धकरून देण्याचे धोरण केले आहे. या धोरणाला अनुसरून महाराष्ट्र राज्यातील ऊस उत्पादक'+
        'शेतकरी या मोबाईल अँप द्वारे शेतकऱ्याच्या ऊस गाळप बाबतची माहिती, FRP नुसार होणारी देय रक्कम व रक्कम'+
        'अदायगीची माहिती शेतकऱ्यांना लगेच उपलब्ध असेल.'
    }
];

/**
 * Director message component
 */
class DirectorMessage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { classes } = this.props;

        return (
            <React.Fragment>
                {
                    directorMessages.map((message, index) => {
                        return <Card className={classes.card}>

                    <Grid container justify="center"
                        alignItems="center"
                        direction="column">
                     <Grid item style={{ marginTop: '15px', marginRight: '15px' }}>
                            <img
                                className={classes.img}
                                src={message.imgPath}
                                alt='director'
                            />
                        </Grid>
                        </Grid>
                           
                            <CardHeader
                                title={message.name}
                                subheader={message.designation}
                            />
                            <CardContent>
                                <Typography paragraph>
                                    {message.message}
                                </Typography>
                                <img
                                    className={classes.signImg}
                                    src={message.sign}
                                    alt='director'
                                />
                            </CardContent>
                            
                        </Card>
                    })


                }


            </React.Fragment>
        );
    }
}

DirectorMessage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(directorMessageStyle, { withTheme: true })((DirectorMessage));
