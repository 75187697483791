import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const privacyPolicyStyle = theme => ({
    card: {
        minWidth: 275,
        margin: '10px'
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
});

/**
 * PrivacyPolicy component
 */
class PrivacyPolicy extends React.Component {

    render() {

        const { classes } = this.props;
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        गोपनीयता धोरण
                    </Typography>

                    <Typography component="p">
                        Help protect your website and its users with clear and fair website terms and conditions.
                         These terms and conditions for a website set out key issues such as acceptable use, privacy, cookies, registration and passwords, intellectual property, links to other sites, termination and disclaimers of responsibility. Terms and conditions are used and necessary to protect a website owner from liability of a user relying on the information or the goods provided from the site then suffering a loss.

                        Making your own terms and conditions for your website is hard,
                        not impossible, to do. It can take a few hours to few days for a person with no legal background to make. But worry no more; we are here to help you out.

                    </Typography>
                </CardContent>
            </Card>

        );

    }
}

PrivacyPolicy.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(privacyPolicyStyle, { withTheme: true })((PrivacyPolicy));