import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/app.container';

import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { history } from './helpers/history';
import * as serviceWorker from './serviceWorker';
// import { ConnectedRouter } from 'react-router-redux';
import {Router} from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-131501072-1');

ReactDOM.render(
    <Provider store={configureStore()}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();