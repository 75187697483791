import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import faqStyle from '../../assets/jss/components/pages/faqStyle';


function getSteps() {
    return [
        'मला माझी माहिती पाहण्यासाठी काय करावे लागेल?',
        'कारखान्यांमध्ये मोबाईल नंबर नोंदवणे अनिवार्य आहे का?',
        'कारखान्याकडे मोबाईल नंबर नोंदवण्यासाठी काय करावे?',
        'माझी माहिती दिसत नसेल तर काय करावे?',
        'माझी माहिती सुरक्षित आहे का?',
        'माझी माहिती इतर व्यक्तींना दिसणार आहे का?',
        'मला सादर केलेल्या माहिती बद्दल शंका असेल तर काय करावे?',
        'माझा मोबाईल नंबर बदलला असेल तर काय करावे?',
        'माझा मोबाईल बदलला तर काय करावे?',
    ];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'आपण "महाराष्ट्र राज्य ऊस उत्पादक शेतकरी" हे मोबाईल अँप INSTALL करून घ्यावे. हे मोबाईल अँप वापरण्यासाठी नवीन शेतकरी नोंदणी करणे आवश्यक आहे. नवीन शेतकरी नोंदणी हि आपण मोबाईल नंबर पडताळणी करून करू शकता. मोबाईल नंबर पडताळणीसाठी One Time Password (OTP) मोबाईल नंबर वरती SMS द्वारे पाठवला जाईल. मोबाईल नंबर पडताळणी झाल्यानंतर आपण साखर कारखान्यांनी महाराष्ट्र शासनाला आपणासंदर्भात सादर केलेली माहिती पाहू शकता.';
        case 1:
            return 'होय. महाराष्ट्र शासन आपणाला वयक्तिक माहिती देण्यासाठी माहिती पाहणाऱ्या व्यक्तीची पडताळणी होणे आवश्यक आहे. ती पडताळणी आपण मोबाईल नंबर द्वारे करतो.';
        case 2:
            return 'आपण कारखान्यातील कृषी अथवा अर्थ विभागातल्या अधिकाऱ्यांशी संपर्क साधून मोबाईल नंबर नोंदणी करून घ्यावा.';
        case 3:
            return 'या मोबाईल अँप द्वारे आपण वयक्तिक शेतकर्यां बाबत साखर कारखान्यांनी महाराष्ट्र शासनाला सादर केलेली माहिती पाहता. हि माहितीसाठी मोबाईल नंबर ची नोंदणी साखर कारखान्यांमध्ये करणे अनिवार्य आहे. आपण मोबाइलला नंबर ची नोंदणी केली नसेल तर साखर कारखान्यांतील अधिकाऱ्याशी संपर्क साधून मोबाईल नंबर नोंदवून घ्यावे.'+ 
            'मोबाईल नम्बर नोंदवला असेल आणि तरीही माहिती दिसत नसेल तर साखर कारखान्याने ती माहिती महाराष्ट्र शासनाला सादर केली नसेल. अधिक माहिती साठी आपण साखर कारखान्यातील अधिकाऱ्यांशी संपर्क साधावा';
        
        case 4:
            return 'होय. आपली माहिती आपण नोंदणी केलेल्या मोबाईल नंबर One Time Password (OTP) ने पडताळणी केल्याशिवाय पाहता येत नाही. पडताळणी केलेले मोबाईल नंबरच त्यांची माहिती पाहण्यासाठी पात्र आहेत. इतर मोबाईल नंबर वरती माहिती दिसणार नाही.';
        
        case 5:
            return 'नाही. आपली माहिती इतर व्यक्तींना दिसणार नाही. शेतकऱ्यांचा नावे साखर कारखान्यांनी नोंदणी केलेल्या मोबाईल नम्बर वरतीच ती माहिती उपलब्ध असेल.';

        case 6: 
            return 'या मोबाईल अँप द्वारे आपण, आपल्या बद्दल साखर कारखान्यांनी महाराष्ट्र शासनाला वैयक्तिक शेतकऱ्याचे ऊस गाळप, FRP , FRP  नुसार देय रक्कम, वजावट यांची जमा केलेली माहिती पाहता. या माहिती मध्ये काही शंका असेल तर आपण सादर कारखान्यातील अधिकाऱ्यांना भेटावे व शंकांचे निरसन करावे.';
        
        case 7:
            return 'बदलेला मोबाईल नंबर साखर कारखान्यांच्या कडे नोंदवावा लागेल. या मोबाईल अँप मध्ये आपणास बदलेल्या मोबाईल नंबर ने नव्याने नोंदणी करावे लागेल.';

        case 8:
            return 'बदलेल्या मोबाईल वरती आपण "महाराष्ट्र राज्य ऊस उत्पादक शेतकरी" अँप INSTALL करून घ्यावे. आपण नोंदणी केलेल्या मोबाईल नंबर व PASSWORD ने आपण आपली माहिती पाहू शकता.';

        default:
            return 'Unknown step';
    }
}


/**
 * FAQ component
 */
class Faq extends React.Component {

    state = {
        activeStep: 0,
    };

    activateStepper = (step) => {
        this.setState(state => ({
            activeStep: step,
        }));
    }

    render() {
        const { classes } = this.props;
        const steps = getSteps();

        return (
            <Paper className={classes.root}>
                {steps.map((label, index) => (
                    <ExpansionPanel style={{ marginTop: '5px' }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Grid container
                                direction="row"
                                justify="flex-start"
                                alignItems="center">
                                {/* <Grid item>
                                    <Avatar className={classes.avatar}>{index + 1}</Avatar>
                                </Grid> */}

                                <Grid item>
                                    <Typography style={{ paddingLeft: '5px' }} variant='subheading'>{label}</Typography>
                                </Grid>
                            </Grid>




                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>{getStepContent(index)}</Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </Paper>
        );
    }
}

Faq.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(faqStyle)(Faq);
