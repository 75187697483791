import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import dashboardStyle from '../../assets/jss/components/pages/dashboardStyle';

import ReactGA from 'react-ga';
import DashboardSummary from "./DashboardSummary";
import DashboardGPSummary from "./DashboardGPSummary";
import { authDetails } from "../../auth/authdetails";

/**
 * Dashboard component
 */
class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userDetails: {},
            openMobileInfoDialog: false,
            year: 2018
        }
    }

    gaEvent = (category, event, label) => {
        ReactGA.event({
            category: category,
            action: event,
            label: JSON.stringify(label)
        });
    }

    componentWillMount() {
        this.gaEvent('Passbook', 'Get galap payment details', 'Get galap payment details action');
        this.props.getGalapPaymentDetails(this.state.year);
        this.setState({ userDetails: authDetails() })
    }

    componentDidMount() {
        // this.setState({ userDetails: authDetails() })
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        this.props.getGalapPaymentDetails(event.target.value);
    };

    render() {

        const { userDetails } = this.state;
        const { classes } = this.props;
        const { summary, factoryCodes, factoryCodeWiseGalapPaymentInfo } = this.props;
        const { isInProgress, isInfoAvailable } = this.props;

        return (
            // <div className={classes.root}>
                <Grid container justify="center"
                    alignItems="center"
                    direction="column" xs>

                    <Grid container justify="center"
                        alignItems="center"
                        direction="row">
                        <Grid item style={{ marginTop: '15px', marginRight: '15px' }}>
                            <InputLabel htmlFor="year-helper"><b>वर्ष</b></InputLabel>
                        </Grid>
                        <Grid item style={{ marginTop: '10px' }}>

                            <Select
                                value={this.state.year}
                                onChange={this.handleChange}
                                inputProps={{
                                    name: 'year',
                                    id: 'year-simple',
                                }}
                            >
                                <MenuItem value={2018}>2018-19</MenuItem>
                                <MenuItem value={2017}>2017-18</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    {!isInProgress ?
                        isInfoAvailable ?
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="caption" style={{ color: '#1976d2', textAlign: 'center', fontSize: '12px' }}>
                                        <b>साखर कारखान्यातर्फे महाराष्ट्र राज्य शासनाला {this.state.year}-{(this.state.year + 1).toString().substr(-2)} वर्षा साठी,
                                            आपल्या ऊस पुरवठा आणि FRP देय रक्कमे संदर्भात दिलेली माहिती</b>
                                    </Typography>

                                    <Paper style={{ marginTop: '10px', padding: '5px', backgroundColor: '#fff9c4' }}>

                                        <Grid container direction="row"
                                            justify="flex-start"
                                            alignItems="flex-start" >
                                            <Grid item>
                                                <Typography variant="caption" style={{ color: '#d32f2f' }}>
                                                    मोबाईल न. <b>{userDetails.userInfo.userName}</b> हा <b>{factoryCodes.length}</b> साखर कारखान्यामध्ये नोंदविला आहे.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    <DashboardSummary classes={classes} summary={summary} year={this.state.year} />

                                    <DashboardGPSummary classes={classes}   history={this.props.history}
                                        factoryCodeWiseGalapPaymentInfo={factoryCodeWiseGalapPaymentInfo}
                                        factoryCodes={factoryCodes} gaEvent={this.gaEvent} />
                                </CardContent>
                            </Card> :
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="caption" style={{ color: '#1976d2', textAlign: 'center', fontSize: '12px' }}>
                                        <b>{this.state.year}-{(this.state.year + 1).toString().substr(-2)} वर्षा साठी, <br /> {userDetails.userInfo.userName} या मोबाईल नंबरसाठी माहिती उपलब्ध नाही.<br />

                                            कृपया आपला मोबाईल नंबर तुमच्या संदर्भीत कारखान्यामध्ये register करावा.</b>
                                    </Typography>
                                </CardContent>
                            </Card>
                        : <Grid item> <CircularProgress style={{ width: '20px', height: '20px' }} /></Grid>
                    }
                </Grid>
            // </div>
        );

    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle, { withTheme: true })((Dashboard));