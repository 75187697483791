import { getAxiosInstance } from '../configs/axios.config';
import { REGISTER, SET_MESSAGE } from '../constants/action.types';

const REGISTER_PROGRESS = 'REGISTER_PROGRESS';
const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const REGISTER_FAILURE = 'REGISTER_FAILURE';
const RESET_REGISTER_STATE = 'RESET_REGISTER_STATE';

const SEND_OTP_PROGRESS = 'SEND_OTP_PROGRESS';
const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';

const VERIFY_OTP_PROGRESS = 'VERIFY_OTP_PROGRESS';
const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const sendOtpProgress = () => { return { type: SEND_OTP_PROGRESS } };

export const sendOtpSuccess = () => { return { type: SEND_OTP_SUCCESS } };

export const sendOtpFailure = (message) => { return { type: SEND_OTP_FAILURE } };

export const verifyOtpProgress = () => { return { type: VERIFY_OTP_PROGRESS } };

export const verifyOtpSuccess = () => { return { type: VERIFY_OTP_SUCCESS } };

export const verifyOtpFailure = (message) => { return { type: VERIFY_OTP_FAILURE } };

export const registerProgress = () => { return { type: REGISTER_PROGRESS } };

export const registerSuccess = (data) => { return { type: REGISTER_SUCCESS } };

export const registerFailure = (message) => { return { type: REGISTER_FAILURE } };

export const sendOtp = (mobileNo) => (dispatch) => {

    if (!navigator.onLine) {
        dispatch({ type: SET_MESSAGE, message: 'कृपया आपले इंटरनेट जोडणी तपासा' });
        return;
    }

    dispatch(sendOtpProgress());

    let axios = getAxiosInstance();

    axios.post('sendotp/', { mobileNo })
        .then(function (response) {
            dispatch(sendOtpSuccess(response.data));
            dispatch({ type: SET_MESSAGE, message: "OTP आपल्या रजिस्टर मोबाईल न. ला पाठविला आहे" })
        }).catch(function (error) {
            if (error && error.response
                && error.response.data &&
                error.response.data.message) {
                dispatch({ type: SET_MESSAGE, message: error.response.data.message });
            }
            else { dispatch({ type: SET_MESSAGE, message: 'Server Error' }) }
        });
}

export const verifyOtp = (mobileNo, otp) => (dispatch) => {

    if (!navigator.onLine) {
        dispatch({ type: SET_MESSAGE, message: 'कृपया आपले इंटरनेट जोडणी तपासा' });
        return;
    }

    dispatch(verifyOtpProgress());

    let axios = getAxiosInstance();

    let action = "REGISTER_VERIFY_OTP";
    axios.post('verifyotp/', { mobileNo, otp, action })
        .then(function (response) {
            dispatch(verifyOtpSuccess(response.data));
        }).catch(function (error) {
            if (error && error.response
                && error.response.data &&
                error.response.data.message) {
                dispatch({ type: SET_MESSAGE, message: error.response.data.message });
            }
            else { dispatch({ type: SET_MESSAGE, message: 'Server Error' }) }
        });
}

export const register = (userData) => (dispatch) => {

    if (!navigator.onLine) {
        dispatch({ type: SET_MESSAGE, message: 'कृपया आपले इंटरनेट जोडणी तपासा' });
        return;
    }

    dispatch(registerProgress());

    let axios = getAxiosInstance();

    axios.post('register/', userData)
        .then(function (response) {
            dispatch(registerSuccess(response.data));

            dispatch({ type: SET_MESSAGE, message: response.data.message })

            // Redirect to login page after registration.
            setTimeout(() => { dispatch({ type: REGISTER }) }, 1000);

        }).catch(function (error) {
            if (error && error.response
                && error.response.data &&
                error.response.data.message) {
                dispatch({ type: SET_MESSAGE, message: error.response.data.message });
            }
            else { dispatch({ type: SET_MESSAGE, message: 'Server Error' }) }
        });
}

export const resetRegisterState = () => (dispatch) => {
    dispatch({ type: RESET_REGISTER_STATE });
}

let initialState = {
    user: {},
    errors: '',
    message: undefined,
    isInProgress: false,
    otpSend: false,
    mobileVerify: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SEND_OTP_PROGRESS:
            return { ...state, isInProgress: true };
        case SEND_OTP_SUCCESS:
            return { ...state, isInProgress: false, otpSend: true };
        case SEND_OTP_FAILURE:
            return { ...state, errors: action.errors, isInProgress: false };

        case VERIFY_OTP_PROGRESS:
            return { ...state, isInProgress: true };
        case VERIFY_OTP_SUCCESS:
            return { ...state, isInProgress: false, mobileVerify: true };
        case VERIFY_OTP_FAILURE:
            return { ...state, errors: action.errors, isInProgress: false };

        case REGISTER_PROGRESS:
            return { ...state, isInProgress: true };
        case REGISTER_SUCCESS:
            return {
                ...state, isInProgress: false, otpSend: false,
                mobileVerify: false
            };
        case RESET_REGISTER_STATE:
            return { ...state, otpSend: false, mobileVerify: false };

        case REGISTER_FAILURE:
            return { ...state, errors: action.errors, isInProgress: false };

        default:
            return state;
    }
}