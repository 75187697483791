import {
    LOGIN, LOGOUT, REGISTER,
    REDIRECT, SET_MESSAGE, RESET_MESSAGE,
    PROFILE_CHANGE
} from '../constants/action.types';
import { authDetails } from '../auth/authdetails';

let initialState = {

    isAuthenticated: false,
    redirectTo: null,
    message: null
}

export const onLogout = () => (dispatch) => {

    sessionStorage.removeItem('user');
    dispatch({ type: LOGOUT });
}

export const onRedirect = () => (dispatch) => {
    dispatch({ type: REDIRECT });
}

export const resetMessage = () => (dispatch) => {
    dispatch({ type: RESET_MESSAGE });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, isAuthenticated: true, redirectTo: '/passbook' };

        case LOGOUT:
            return { ...state, isAuthenticated: false };

        case REDIRECT:
            return { ...state, redirectTo: null };

        case REGISTER:
            return { ...state, redirectTo: '/login' };
        
            case PROFILE_CHANGE:
            return { ...state, redirectTo: '/' };

        case SET_MESSAGE:
            return { ...state, message: action.message };

        case RESET_MESSAGE:
            return { ...state, message: null };
        default:
            return { ...state, isAuthenticated: authDetails() ? true : false };
    }
}