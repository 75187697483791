import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';

const GET_GALAP_PAYMENT_DETAILS_SUCCESS = "GET_GALAP_PAYMENT_DETAILS_SUCCESS";
const GET_GALAP_PAYMENT_DETAILS_PROGRESS = "GET_GALAP_PAYMENT_DETAILS_PROGRESS";
const GET_GALAP_PAYMENT_DETAILS_FAILURE = "GET_GALAP_PAYMENT_DETAILS_FAILURE";

export const getGalapPaymentDetailsSuccess = (galapPaymentDetails) => {
    return {
        type: GET_GALAP_PAYMENT_DETAILS_SUCCESS,
        galapPaymentDetails
    }
}

export const getGalapPaymentDetails = (year) => (dispatch) => {

    dispatch({ type: GET_GALAP_PAYMENT_DETAILS_PROGRESS });

    let axios = getAxiosInstance();
    const auth = authDetails();
    axios.get('galap', { params: { mobileNo: auth.userInfo.userName, year: year } }
    ).then(function (response) {
        dispatch(getGalapPaymentDetailsSuccess(response.data));
    }).catch(function (error) {
        dispatch({ type: GET_GALAP_PAYMENT_DETAILS_FAILURE });
    });
}

const reducer = (accumulator, currentValue) => accumulator + currentValue;

function getTotalSummary(galapPaymentDetails) {

    let totalMetricTon = 0;
    let totalAmountToPay = 0;
    let totalPaidAmount = 0;
    let totalBalanceAmount = 0;
    let totalDeductionAmount = 0;
    let year = '';

    if (galapPaymentDetails.galapInfo && galapPaymentDetails.galapInfo.length > 0) {
        totalMetricTon = galapPaymentDetails.galapInfo.map(info => info.metricTon).reduce(reducer);
        totalAmountToPay = galapPaymentDetails.galapInfo.map(info => info.amountToPay).reduce(reducer);
    }

    if (galapPaymentDetails.paymentInfo && galapPaymentDetails.paymentInfo.length > 0) {
        totalPaidAmount = galapPaymentDetails.paymentInfo.map(info => info.paidAmount).reduce(reducer);
    }

    if (galapPaymentDetails.deductionInfo && galapPaymentDetails.deductionInfo.length > 0) {

        for (let index = 0; index < galapPaymentDetails.deductionInfo.length; index++) {
            if (galapPaymentDetails.deductionInfo[index].deductionDetails.length > 0) {
                totalDeductionAmount += galapPaymentDetails.deductionInfo[index].deductionDetails.
                    map(info => info.deductionAmount).reduce(reducer);
            }
        }
    }

    totalBalanceAmount = totalAmountToPay - totalPaidAmount;
    totalBalanceAmount = totalBalanceAmount <= 0 ? 0 : totalBalanceAmount;
    year = galapPaymentDetails.year;

    return { year, totalMetricTon, totalAmountToPay, totalPaidAmount, totalBalanceAmount, totalDeductionAmount };
}

function groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}

function getFactorywiseSummary(galapPaymentDetails) {

    var factoryWiseGalapInfo = groupBy(galapPaymentDetails.galapInfo, 'factoryCode');
    var factoryWisePaymentInfo = groupBy(galapPaymentDetails.paymentInfo, 'factoryCode');
    var factoryWiseDeducInfo = groupBy(galapPaymentDetails.deductionInfo, 'factoryCode');

    var factoryCodeGalapWise = [...new Set(galapPaymentDetails.galapInfo.map(item => item.factoryCode))]
    var factoryCodePaymentWise = [...new Set(galapPaymentDetails.paymentInfo.map(item => item.factoryCode))]
    var factoryCodeDeductionWise = [...new Set(galapPaymentDetails.deductionInfo.map(item => item.factoryCode))]

    var commonFactoryCodes = [...new Set(factoryCodeGalapWise.concat(factoryCodePaymentWise))];

    let factoryWiseGalapPaymentSummary = [];

    commonFactoryCodes.forEach(element => {

        let totalMetricTon = 0
        let totalAmountToPay = 0;
        let totalPaidAmount = 0;
        let totalDeductionAmount = 0;
        let totalBalanceAmount = 0;
        let year = '';
        let factoryCode = '';
        let factoryName = ''
        let frpRate = 0;

        // Set value if the factory code is avaliable in the galap info.
        if (factoryWiseGalapInfo[element] && factoryWiseGalapInfo[element].length > 0) {
            totalMetricTon = factoryWiseGalapInfo[element].map(info => info.metricTon).reduce(reducer);
            totalAmountToPay = factoryWiseGalapInfo[element].map(info => info.amountToPay).reduce(reducer);
            year = factoryWiseGalapInfo[element][0].year;
            factoryCode = factoryWiseGalapInfo[element][0].factoryCode;
            factoryName = factoryWiseGalapInfo[element][0].factoryName;
            frpRate = factoryWiseGalapInfo[element][0].frpRate;
        }

        // Set value if the factory code is avaliable in the payment info.
        if (factoryWisePaymentInfo[element] && factoryWisePaymentInfo[element].length > 0) {
            totalPaidAmount = factoryWisePaymentInfo[element].map(info => info.paidAmount).reduce(reducer);

            // TODO: need to remove from here.
            frpRate = frpRate === 0 ? factoryWisePaymentInfo[element][0].frpRate : 0;
        }

        if (factoryWiseDeducInfo[element] && factoryWiseDeducInfo[element].length > 0) {
            let deductionInfo = factoryWiseDeducInfo[element];

            for (let index = 0; index < deductionInfo.length; index++) {
                if (deductionInfo[index].deductionDetails.length > 0) {
                    totalDeductionAmount += deductionInfo[index].deductionDetails.
                        map(info => info.deductionAmount).reduce(reducer);
                }
            }
        }

        totalBalanceAmount = totalAmountToPay - totalPaidAmount;
        totalBalanceAmount = totalBalanceAmount <= 0 ? 0 : totalBalanceAmount;

        factoryWiseGalapPaymentSummary.push({
            totalPaidAmount, totalMetricTon, totalAmountToPay,
            totalBalanceAmount, totalDeductionAmount, year, factoryCode, factoryName, frpRate
        })
    });

    return factoryWiseGalapPaymentSummary;
}

function getGalapPaymentFactoryCode(galapPaymentDetails) {
    var factoryCodeGalapWise = [...new Set(galapPaymentDetails.galapInfo.map(item => item.factoryCode))]
    var factoryCodePaymentWise = [...new Set(galapPaymentDetails.paymentInfo.map(item => item.factoryCode))]

    var commonFactoryCodes = [...new Set(factoryCodeGalapWise.concat(factoryCodePaymentWise))];

    return commonFactoryCodes;
}

const isInfoAvailable = (galapPaymentDetails) => {
    if ((galapPaymentDetails.galapInfo && galapPaymentDetails.galapInfo.length > 0) ||
        (galapPaymentDetails.paymentInfo && galapPaymentDetails.paymentInfo.length > 0)) {
        return true;
    }

    return false;
}

let initialState = {
    summary: {},
    factoryWiseSummary: [],
    galapPaymentDetails: [],
    factoryCodes: [],
    factoryCodeWiseGalapPaymentInfo: {},
    errors: '',
    isInProgress: false,
    isInfoAvailable: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_GALAP_PAYMENT_DETAILS_PROGRESS:
            return { ...state, isInProgress: true };

        case GET_GALAP_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state, summary: getTotalSummary(action.galapPaymentDetails),
                factoryWiseSummary: getFactorywiseSummary(action.galapPaymentDetails),
                factoryCodeWiseGalapPaymentInfo: {
                    factoryWiseGalapInfo: groupBy(action.galapPaymentDetails.galapInfo, 'factoryCode'),
                    factoryWisePaymentInfo: groupBy(action.galapPaymentDetails.paymentInfo, 'factoryCode'),
                    factoryWiseDeductionInfo: groupBy(action.galapPaymentDetails.deductionInfo, 'factoryCode'),
                },
                factoryCodes: getGalapPaymentFactoryCode(action.galapPaymentDetails),
                isInProgress: false,
                isInfoAvailable: isInfoAvailable(action.galapPaymentDetails)
            };

        case GET_GALAP_PAYMENT_DETAILS_FAILURE:
            return { ...state, isInProgress: false, isInfoAvailable: false };

        default:
            return state;
    }
}