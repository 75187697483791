import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PersonOutline from '@material-ui/icons/PersonOutlined';
import ReactGA from 'react-ga';

const profileStyle = theme => ({
    paper: {
        margin: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
});

/**
 * Profile component
 */
class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            changeProfileForm: {
                firstName: '',
                middleName: '',
                lastName: '',
                address: '',
                village: '',
                taluka: '',
                district: '',
                state: '',
                pincode: '',
                password: ''
            },
            submitted: false
        }
    }

    handleChangeProfileChange = (event) => {
        const { changeProfileForm } = this.state;
        changeProfileForm[event.target.name] = event.target.value;
        this.setState({ changeProfileForm });
    }

    handleChangeProfileSubmit = () => {

        ReactGA.event({
            category: 'User',
            action: 'Change Profile'
        });

        let user = {};
        user.firstName = this.state.changeProfileForm.firstName;
        user.middleName = this.state.changeProfileForm.middleName;
        user.lastName = this.state.changeProfileForm.lastName;
        user.address = this.state.changeProfileForm.address;
        user.village = this.state.changeProfileForm.village;
        user.taluka = this.state.changeProfileForm.taluka;
        user.district = this.state.changeProfileForm.district;
        user.pincode = this.state.changeProfileForm.pincode;
        user.state = this.state.changeProfileForm.state;

        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });

        this.props.changeProfile(user);
    }

    componentWillMount() {
        this.props.getProfileData();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            let user = nextProps.user;
            this.setState({ changeProfileForm: user });
        }
    }

    render() {
        const { classes } = this.props;
        const { changeProfileForm, submitted } = this.state;
        return (
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <PersonOutline />
                </Avatar>
                <Typography component="h1" variant="h5">
                    माझी माहिती
                </Typography>
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleChangeProfileSubmit}
                    className={classes.form}
                >
                    <TextValidator
                        label="पहिले नाव"
                        onChange={this.handleChangeProfileChange}
                        fullWidth
                        name="firstName"
                        value={changeProfileForm.firstName}
                    // validators={['required']}
                    // errorMessages={['पहिले नाव भरा']}
                    />

                    <TextValidator
                        label="मधले नाव"
                        onChange={this.handleChangeProfileChange}
                        fullWidth
                        name="middleName"
                        value={changeProfileForm.middleName}
                    // validators={['required']}
                    // errorMessages={['मधले नाव भरा']}
                    />

                    <TextValidator
                        label="आडनाव"
                        onChange={this.handleChangeProfileChange}
                        fullWidth
                        name="lastName"
                        value={changeProfileForm.lastName}
                    // validators={['required']}
                    // errorMessages={['आडनाव भरा']}
                    />

                    <TextValidator
                        label="पत्ता"
                        onChange={this.handleChangeProfileChange}
                        fullWidth
                        name="address"
                        value={changeProfileForm.address}
                    // validators={['required']}
                    // errorMessages={['पत्ता भरा']}
                    />

                    <TextValidator
                        label="गाव"
                        onChange={this.handleChangeProfileChange}
                        fullWidth
                        name="village"
                        value={changeProfileForm.village}
                    // validators={['required']}
                    // errorMessages={['गाव भरा']}
                    />

                    <TextValidator
                        label="तालुका"
                        onChange={this.handleChangeProfileChange}
                        fullWidth
                        name="taluka"
                        value={changeProfileForm.taluka}
                    // validators={['required']}
                    // errorMessages={['तालुका भरा']}
                    />

                    <TextValidator
                        label="जिल्हा"
                        onChange={this.handleChangeProfileChange}
                        fullWidth
                        name="district"
                        value={changeProfileForm.district}
                    // validators={['required']}
                    // errorMessages={['जिल्हा भरा']}
                    />

                    <TextValidator
                        label="पिनकोड"
                        onChange={this.handleChangeProfileChange}
                        fullWidth
                        name="pincode"
                        value={changeProfileForm.pincode}
                    // validators={['required']}
                    // errorMessages={['पिनकोड भरा']}
                    />

                    <TextValidator
                        label="राज्य"
                        onChange={this.handleChangeProfileChange}
                        fullWidth
                        name="state"
                        value={changeProfileForm.state}
                    // validators={['required']}
                    // errorMessages={['राज्य भरा']}
                    />

                    <Grid container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                        <Grid item>
                            <Button
                                disabled={submitted}
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                जतन करा

                                </Button>
                        </Grid>
                    </Grid>

                </ValidatorForm>
            </Paper>
        );

    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(profileStyle, { withTheme: true })((Profile));
