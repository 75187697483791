import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import loginStyle from '../../assets/jss/components/pages/loginStyle';

import { authDetails } from '../../auth/authdetails';

/**
 * Login component
 */
class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                mobileNo: '',
                password: '',
            },
            showPassword: false,
            submitted: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.logIn = this.logIn.bind(this);
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleChange(event) {
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    handleSubmit() {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });

        this.props.onLogIn(this.state.formData.mobileNo.trim(), this.state.formData.password)
    }

    handleInputChange(e) {
        let state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state });
    }

    logIn() {
        this.props.onLogIn(this.state.formData.mobileNo, this.state.formData.password)
    }

    register = () => {
        this.props.history.push('/register');
    }

    forgotPassword = () => {
        this.props.history.push('/forgotpassword');
    }

    componentWillReceiveProps(nextProps) {
    }

    componentWillMount() {
        if (authDetails()) {
            this.props.history.push('/');
        }
    }

    render() {

        const { classes } = this.props;
        const { formData, submitted } = this.state;

        return (
            <main className={classes.main}>
                <CssBaseline />
                {/* <Slide direction="down" in={true} mountOnEnter unmountOnExit> */}
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockIcon />
                        </Avatar>
                        <Typography style={{ fontSize: '18px' }}>
                            महाराष्ट्र राज्य ऊस उत्पादक शेतकरी
                        </Typography>
                        <Typography variant="h6" style={{ color: 'blue' }}>
                            Welcome!
                        </Typography>
                        <Typography variant="caption">
                            माहिती पाहण्यासाठी Login करा
                        </Typography>

                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            className={classes.form}
                        >
                            <TextValidator
                                label="मोबाईल नंबर \ Mobile Number"
                                onChange={this.handleChange}
                                fullWidth
                                name="mobileNo"
                                type="number"
                                value={formData.mobileNo}
                                validators={['required']}
                                errorMessages={['मोबाईल न. भरा']}
                            />
                            <TextValidator
                                label="पासवर्ड \ Password"
                                onChange={this.handleChange}
                                fullWidth
                                name="password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={formData.password}
                                validators={['required']}
                                errorMessages={['पासवर्ड भरा']}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                        >
                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }}

                            />

                            <Button
                                type="submit"
                                disabled={submitted}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                style={{ textTransform: 'none' }}
                            >
                                Login
                        </Button>
                        </ValidatorForm>
                        <Grid container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start">
                            <Grid item>
                                <Button color="primary" style={{ fontSize: '12px', textTransform: 'none', paddingLeft: '0px' }} onClick={this.forgotPassword}>
                                    पासवर्ड विसरलात का?
                                    </Button>
                            </Grid>

                            <Grid item>
                                <Button color="primary" style={{ fontSize: '12px', textTransform: 'none', paddingLeft: '0px' }} onClick={this.register} >
                                    नवीन शेतकरी नोंदणीसाठी येथे Click करा
                                    </Button>
                            </Grid>
                        </Grid>

                    </Paper>
                {/* </Slide> */}
            </main >
        );

    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(loginStyle)(Login);

export default withStyles(loginStyle, { withTheme: true })((Login));
