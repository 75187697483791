import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const contactUsStyle = theme => ({
    card: {
        minWidth: 275,
        margin:'10px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
});

/**
 * ContactUs component
 */
class ContactUs extends React.Component {

    render() {

        const { classes } = this.props;
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Contact us
                </Typography>
                    <Typography component="p">
                        supportcfpb@deavnet.com
                    </Typography>
                </CardContent>
            </Card>
        );

    }
}

ContactUs.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(contactUsStyle, { withTheme: true })((ContactUs));