import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger'
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { history } from './helpers/history';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { googleAnalytics } from './configs/react.ga.config';

import dashboardReducer from './reducers/dashboard.reducer';
import helpReducer from './reducers/help.reducer';
import loginReducer from './reducers/login.reducer';
import registerReducer from './reducers/register.reducer';
import forgotPasswordReducer from './reducers/forgot.password.reducer';
import profileReducer from './reducers/profile.reducer';
import headerReducer from './reducers/header.reducer';
import commonReducer from './reducers/common.reducer';

// Build the middleware for intercepting and dispatching navigation actions
const routerMiddlewareResult = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return compose(applyMiddleware(thunkMiddleware, routerMiddlewareResult),
      applyMiddleware(googleAnalytics));
  } else {
    // Enable additional logging in non-production environments.
    return compose(applyMiddleware(thunkMiddleware, routerMiddlewareResult, createLogger()),
      applyMiddleware(googleAnalytics))
  }
};

export default function configureStore() {
  return createStore(
    combineReducers({
      dashboard: dashboardReducer,
      help: helpReducer,
      login: loginReducer,
      register: registerReducer,
      profile: profileReducer,
      common: commonReducer,
      header: headerReducer,
      router: routerReducer,
      forgotPassword: forgotPasswordReducer
    }),
    composeWithDevTools(getMiddleware())
  )
}