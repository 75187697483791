import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import homeImage from '../../assets/img/far-512.png';

import { authDetails } from '../../auth/authdetails';

const homeStyle = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 1,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    bigAvatar: {
        margin: 10,
        width: 180,
        height: 180,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    paper: {
        marginTop: theme.spacing.unit * 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    },
    card: {
        minWidth: 275,
        margin: '10px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    media: {
        height: 340,
        margin: 5
    },
    actions: {
        display: 'flex',
    },
});

/**
 * Home component
 */
class Home extends React.Component {

    redirectTo = (page) => {
        this.props.history.push(page);
    }

    render() {

        const { classes } = this.props;

        return (
            <main className={classes.main}>
                {/* <CssBaseline /> */}
                {/* <Slide direction="down" in={true} mountOnEnter unmountOnExit> */}
                    <Paper className={classes.paper}>
                        <Avatar className={classes.bigAvatar} src={homeImage}
                            style={{ overflow: 'unset' }}>
                        </Avatar>

                        <Typography style={{ fontSize: '15px', marginTop: '10px'}} align='justify'>
                            महाराष्ट्र राज्य साखर आयुक्तालय, महाराष्ट्र शासन, यांनी महाराष्ट्र राज्यातील ऊस उत्पादक शेतकऱ्यांसाठी विविध उपयुक्त
                            माहिती सुलभरित्या उपलब्धकरून देण्याचे धोरण केले आहे. या धोरणाला अनुसरून महाराष्ट्र राज्यातील ऊस उत्पादक
                            शेतकरी या मोबाईल अँप द्वारे शेतकऱ्याच्या ऊस गाळप बाबतची माहिती, FRP नुसार होणारी देय रक्कम व रक्कम
                            अदायगीची माहिती शेतकऱ्यांना लगेच उपलब्ध असेल.
                        </Typography>

                        <Grid container direction="row"
                            justify="center"
                            alignItems="center" style={{ marginTop: '10px' }} >

                            {authDetails() ? <Grid item >

                                <Button style={{ textTransform: 'none', backgroundColor: '#225Be6', color: '#ffffff' }}
                                    onClick={() => { this.redirectTo('/passbook') }}>
                                    माहिती पाहण्यासाठी येथे Click करा</Button>
                            </Grid>
                                :
                                (<React.Fragment>
                                    <Grid item style={{ marginTop: '10px' }}>
                                        <Button style={{ padding: '0px 23px', textTransform: 'none', backgroundColor: '#225Be6', color: '#ffffff'}}
                                            onClick={() => { this.redirectTo('/login') }} >
                                            Login करण्यासाठी येथे Click करा</Button>
                                    </Grid>

                                    <Grid item style={{ marginTop: '10px' }}>
                                        <Button style={{ textTransform: 'none', backgroundColor: '#1eb050', color: '#ffffff' }}
                                            onClick={() => { this.redirectTo('/register') }} >
                                            नवीन नोदणी करण्यासाठी येथे Click करा</Button>
                                    </Grid>
                                </React.Fragment>)}
                        </Grid>

                    </Paper>


                {/* </Slide> */}
            </main >
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(homeStyle, { withTheme: true })((Home));
