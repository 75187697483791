import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Header from '../components/Header';
import * as actions from '../reducers/header.reducer';

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.header.isAuthenticated,
        redirectTo: state.header.redirectTo,
        message: state.header.message,
    };
};

export default withRouter(connect(mapStateToProps, actions)(Header));
