import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dashboard from '../components/pages/Dashboard';
import * as actions from '../reducers/dashboard.reducer';

const mapStateToProps = (state) => {
    return {
        isInProgress: state.dashboard.isInProgress,
        isInfoAvailable: state.dashboard.isInfoAvailable,
        summary: state.dashboard.summary,
        galapPaymentDetails: state.dashboard.galapPaymentDetails,
        factoryWiseSummary: state.dashboard.factoryWiseSummary,
        factoryCodeWiseGalapPaymentInfo: state.dashboard.factoryCodeWiseGalapPaymentInfo,
        factoryCodes: state.dashboard.factoryCodes
    };
};


export default withRouter(connect(mapStateToProps, actions)(Dashboard));
