import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const termsAndConditionsStyle = theme => ({
    card: {
        minWidth: 275,
        margin: '10px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
});

/**
 * TermsAndConditions component
 */
class TermsAndConditions extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography  align="justify" className={classes.title} color="textSecondary" gutterBottom>
                        Terms & Conditions
                    </Typography>

                    <Typography variant="h6">
                        Conditions Relating to Your Use of this mobile application
                    </Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>1. Your Account</b>
                    </Typography>
                     <Typography  align="justify" component="p">
                        If you use this mobile application, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your device to prevent unauthorized access to your account. You agree to accept responsibility for all activities that occur under your account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized manner. Please ensure that the details you provide us with are correct and complete and inform us immediately of any changes to the information that you provided when registering. You can access and update much of the information you provided us with in the “Profile” area of the mobile application. You agree and acknowledge that you will use your account on the mobile application to view the information only for your personal use and not for business purposes. Deavnet reserves the right to refuse access to the mobile application, terminate accounts, remove, or edit content at any time without notice to you.
                    </Typography>
                    <Typography  align="justify" variant="subtitle1" >
                        <b>2. Privacy</b>
                    </Typography>
                    <Typography  align="justify" component="p" paragraph={true}>
                        Please review our Privacy Notice, which also governs your visit to the mobile application, to understand our practices. The personal information / data provided to us by you or your data by sugar factories during the course of usage of the mobile application, software platform, and system will be treated as strictly confidential and in accordance with the Privacy Notice and applicable laws and regulations. If you object to your information being transferred or used, please do not use the mobile application.
                    </Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>3. E-Platform for Communication</b>
                    </Typography>
                    <Typography  align="justify" component="p">

                        You agree, understand, and acknowledge that the mobile application is connected to an online platform that enables you to view your crushing and payment information submitted to Maharashtra State Sugar Commissioner Office by sugar factories. You further agree and acknowledge that Deavnet is only a facilitator and is not and cannot be a party to or control in any manner any transactions on the mobile application and/or software platform. Accordingly, the contract of information on the mobile application shall be a strictly bipartite contract between you and the sugar factories.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>4. Access to the mobile application</b>
                    </Typography>
                    <Typography  align="justify" component="p">


                        We will do our utmost to ensure that availability of the mobile application will be uninterrupted and that transmissions will be error-free. However, due to the nature of the Internet, this cannot be guaranteed. Also, your access to the website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>5. License for mobile application access</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        Subject to your compliance with these Conditions of Use and payment of applicable fees, if any, Deavnet Solutions grants you a limited license to access and make personal use of this mobile application, but not to modify it, or any portion of it, except with express written consent of Deavnet Solutions and / or its affiliates, as may be applicable. This license does not include any resale or commercial use of this mobile application or its contents; any collection and use of any information; any derivative use of this mobile application or its contents; any downloading or copying of account information for the benefit of another developer; or any use of data mining, robots, or similar data gathering and extraction tools.

                        This mobile application or any portion of this mobile application (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent of Deavnet Solutions and / or its affiliates, as may be applicable.

                        You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of this mobile application and its affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilizing Deavnet Solutons or its affiliates' names or trademarks without the express written consent of Deavnet Solutions and / or its affiliates, as applicable. Any unauthorised use terminates the permission or license granted by Deavnet Solutions and / or its affiliates, as applicable.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>6. Your Conduct</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        You must not use this mobile application in any way that causes, or is likely to cause, the website or access to it to be interrupted, damaged or impaired in any way You understand that you, and not Deavnet Solutions, are responsible for all electronic communications and content sent from your mobile device to us and you must use this mobile application for lawful purposes only. You must not use the website for any of the following:

                        ·       for fraudulent purposes, or in connection with a criminal offense or other unlawful activity

                        ·       to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam

                        ·       to cause annoyance, inconvenience or needless anxiety
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>7. Claims against Objectionable Content</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        Because Deavnet Solutions lists millions of farmers sugar cane crushing and payment information provided by sugar factories on the software platform and mobile application and hosts many thousands of record entries, it is not possible for us to be aware of the contents of each farmer, or each transaction information that is displayed. Accordingly, Deavnet Solutions operates on a "notice and takedown" basis. If you believe that any content on the mobile applications is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, (" Objectionable Content "),
                        please notify us immediately on supportcfpb@deavnet.com email.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>8. Copyright, authors' rights and database rights</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        All content included on the mobile application, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Deavnet Solution, its affiliates or its content provider sugar factories. The compilation of all content on this mobile application is the exclusive property of Deavnet Solutions and its affiliates and is protected by laws of India. All software used on this mobile application is the property of Deavnet Solutions, its affiliates or its software suppliers and is protected by India and international copyright and author' rights laws.

                        You may not systematically extract/ or re-utilize parts of the contents of this mobile application without Deavnet Solutions and / or its affiliate's (as may be applicable) express written consent. In particular, you may not utilize any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilization of any substantial parts of this mobile application, without Deavnet Solutions and / or its affiliate's (as may be applicable) express written consent.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>9. Indemnity and Release</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        You shall indemnify and hold harmless Deavnet Solutions, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Conditions of Use or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.

                        You hereby expressly release Deavnet Solutions and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions with the sugar factories and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>10. Communications</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        When you visit this mobile application, you are communicating with us electronically. You will be required to provide a valid phone number. We may communicate with you by e-mail, SMS, phone call or by posting notices on the mobile applications or by any other mode of communication. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the website and/or your order placed on the website.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>11. Losses</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        We will not be responsible for any loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when you commenced using this mobile application.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>12. Alteration of Service or Amendments to the Conditions</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        We reserve the right to make changes to our mobile application, policies, and these Conditions of Use at any time. You will be subject to the policies and Conditions of Use in force at the time that you use this mobile application. If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>13. Events beyond our reasonable control</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.
</Typography>

                    <Typography  align="justify" variant="subtitle1">
                        <b>14. Waiver</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.
</Typography>
                    <Typography  align="justify" variant="subtitle1">
                        <b>15. Governing law and Jurisdiction</b>
                    </Typography>

                    <Typography  align="justify" component="p">
                        These conditions are governed by and construed in accordance with the laws of India. You agree, as we do, to submit to the exclusive jurisdiction of the courts at Pune.
</Typography>



                </CardContent>
            </Card>
        );

    }
}

TermsAndConditions.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(termsAndConditionsStyle, { withTheme: true })((TermsAndConditions));
