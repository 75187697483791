import { connect } from 'react-redux'
import {withRouter} from 'react-router'
import App from '../App';
//import * as actions from '../reducers/login.reducer';

const mapStateToProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps)(App));
