const faqStyle = theme => ({
    root: {
        minWidth: 275,
        margin: '10px'
    },
    avatar: {
        width: '30px',
        height: '30px'        
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    },
});

export default faqStyle;