import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

const CustomTableCell = withStyles(theme => ({
    head: {
        // backgroundColor: theme.palette.common.white,
        // color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class PaymentInfoView extends React.Component {

    constructor(props) {
        super(props);
    }

    getDate = (date) => {
        var monthNames = [
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sept", "Oct",
            "Nov", "Dec"
        ];

        let dateObj = new Date(date);

        var day = dateObj.getDate();
        var monthIndex = dateObj.getMonth();
        var year = dateObj.getFullYear();

        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }

    hidePaymentDetailsDialog = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes, selectedSabhasadDetails, selectedPaymentDetails } = this.props.location.state;

        return (
            <React.Fragment>
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: true,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.hidePaymentDetailsDialog}
                            className={classes.menuButton}
                        >
                            <KeyboardBackspace />
                        </IconButton>

                        <Typography variant="h6" color="inherit" noWrap>
                            रक्कम माहिती
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.toolbar}></div>

                <Grid container justify="center"
                    alignItems="center"
                    direction="column">
                    <Grid item>
                        <Card style={{ margin: '10px' }}>
                            <CardContent style={{ padding: '5px' }}>
                                <Typography variant="caption" style={{ color: '#1976d2', fontSize: '15px' }}>
                                    <b>{selectedSabhasadDetails.factoryName}</b>
                                </Typography>

                                <Grid container direction="row"
                                    justify="space-between"
                                    alignItems="flex-start" >
                                    <Grid item>
                                        <Typography className={classes.title}
                                            variant="h6" style={{ fontSize: '15px' }}>
                                            <b>{selectedSabhasadDetails.sabhasadName}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.title} variant="h6" style={{ fontSize: '15px' }} >
                                            सभासद क्र. <b>{selectedSabhasadDetails.sabhasadCode}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <hr />

                                <Grid container direction="row"
                                    justify="center"
                                    alignItems="center"
                                    style={{ backgroundColor: '#ffcdd2' }} >
                                    <Grid item>
                                        <Typography className={classes.title}
                                            variant="h6" >
                                            <b>रक्कम माहिती</b>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Table style={{ tableLayout: 'fixed' }}>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell className={classes.tableCell}>दिनांक</CustomTableCell>
                                            <CustomTableCell className={classes.tableCell}>बँक</CustomTableCell>
                                            <CustomTableCell className={classes.tableCell}>बँक अकाऊंट न.</CustomTableCell>
                                            <CustomTableCell className={classes.tableCell}>जमा रक्कम(रु)</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedPaymentDetails ?
                                            selectedPaymentDetails.map(row => {
                                                if (row.sabhasadCode === selectedSabhasadDetails.sabhasadCode) {
                                                    return (
                                                        <TableRow className={classes.row} key={row.id}>
                                                            <CustomTableCell className={classes.tableCell}>{this.getDate(row.paymentDate)}</CustomTableCell>
                                                            <CustomTableCell className={classes.tableCell}>{row.bankName}</CustomTableCell>
                                                            <CustomTableCell className={classes.tableCell}>{row.bankAccountNo}</CustomTableCell>
                                                            <CustomTableCell className={classes.tableCell}>{row.paidAmount}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            }) : <tr></tr>}

                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment >
        )
    }
}


export default PaymentInfoView;