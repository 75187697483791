
import { getAxiosInstance } from '../configs/axios.config';
import { LOGIN, SET_MESSAGE} from '../constants/action.types';

const LOGIN_PROGRESS = "LOGIN_PROGRESS";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";

export const loginProgress = () => {
    return {
        type: LOGIN_PROGRESS
    }
};

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        user
    }
};

export const loginFailure = (errors) => {
    return {
        type: LOGIN_FAILURE,
        errors
    }
};

export const onLogIn = (username, password) => (dispatch) => {

    if(!navigator.onLine)
    {
        dispatch({ type: SET_MESSAGE, message: 'कृपया आपले इंटरनेट जोडणी तपासा' });
        return;
    }

    let axios = getAxiosInstance();

    axios.post('login/', {
        username, password
    }).then(function (response) {
        dispatch(loginSuccess(response.data));
         sessionStorage.setItem('user', JSON.stringify(response.data))
        dispatch({ type: LOGIN });
    }).catch(function (error) {
        dispatch(loginFailure(error));
        dispatch({ type: SET_MESSAGE, message: 'मोबाईल न. किवा पासवर्ड चुकीचा आहे' });
        // dispatch({ type: RESET_MESSAGE });
    });
}

let initialState = {
    user: {},
    errors: '',
    isInProgress: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_PROGRESS:
            return { ...state, isInProgress: true };
        case LOGIN_SUCCESS:
            return { ...state, user: action.user, isInProgress: false };
        case LOGIN_FAILURE:
            return { ...state, errors: action.errors, isInProgress: false };

        default:
            return state;
    }
}