import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
import { PROFILE_CHANGE, SET_MESSAGE } from '../constants/action.types';

const CHANGE_PROFILE_PROGRESS = 'CHANGE_PROFILE_PROGRESS';
const CHANGE_PROFILE_SUCCESS = 'CHANGE_PROFILE_SUCCESS';
const CHANGE_PROFILE_FAILURE = 'CHANGE_PROFILE_FAILURE';

const GET_PROFILE_PROGRESS = 'GET_PROFILE_PROGRESS';
const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const changeProfileProgress = () => { return { type: CHANGE_PROFILE_PROGRESS } };

export const changeProfileSuccess = () => { return { type: CHANGE_PROFILE_SUCCESS } };

export const changeProfileFailure = () => { return { type: CHANGE_PROFILE_FAILURE } };


export const getProfileProgress = () => { return { type: GET_PROFILE_PROGRESS } };

export const getProfileSuccess = (userInfo) => { return { type: GET_PROFILE_SUCCESS, userInfo: userInfo } };

export const getProfileFailure = () => { return { type: GET_PROFILE_FAILURE } };

export const changeProfile = (userData) => (dispatch) => {

    if(!navigator.onLine)
    {
        dispatch({ type: SET_MESSAGE, message: 'कृपया आपले इंटरनेट जोडणी तपासा' });
        return;
    }
    
    dispatch(changeProfileProgress());

    let axios = getAxiosInstance();
    const auth = authDetails();
    userData.userName = auth.userInfo.userName;

    axios.post('profile/', userData)
        .then(function (response) {
            dispatch(changeProfileSuccess(response.data));

            dispatch({ type: SET_MESSAGE, message: response.data.message })

            // Redirect to login page after registration.
            setTimeout(() => { dispatch({ type: PROFILE_CHANGE }) }, 1000);

        }).catch(function (error) {
            if (error && error.response
                && error.response.data &&
                error.response.data.message) {
                dispatch({ type: SET_MESSAGE, message: error.response.data.message });
            }
            else { dispatch({ type: SET_MESSAGE, message: 'Server Error' }) }
        });
}

export const getProfileData = () => (dispatch) => {
    dispatch(getProfileProgress());

    let axios = getAxiosInstance();
    const auth = authDetails();
    axios.get('profile', { params: { mobileNo: auth.userInfo.userName } })
        .then(function (response) {
            dispatch(getProfileSuccess(response.data));
        }).catch(function (error) {
            if (error && error.response
                && error.response.data &&
                error.response.data.message) {
                dispatch({ type: SET_MESSAGE, message: error.response.data.message });
            }
            else { dispatch({ type: SET_MESSAGE, message: 'Server Error' }) }
        });
}

let initialState = {
    user: undefined,
    errors: '',
    isInProgress: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PROFILE_PROGRESS:
            return { ...state, isInProgress: true };

        case CHANGE_PROFILE_SUCCESS:
            return {
                ...state, isInProgress: false
            };

        case CHANGE_PROFILE_FAILURE:
            return { ...state, errors: action.errors, isInProgress: false };

        case GET_PROFILE_PROGRESS:
            return { ...state, isInProgress: true };

        case GET_PROFILE_SUCCESS:
            return { ...state, user: action.userInfo, isInProgress: false };

        default:
            return state;
    }
}