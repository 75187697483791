import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ForgotPassword from '../components/pages/ForgotPassword';
import * as actions from '../reducers/forgot.password.reducer';

const mapStateToProps = (state) => {
    return {
        isInProgress: state.forgotPassword.isInProgress,
        mobileVerify: state.forgotPassword.mobileVerify,
        otpSend: state.forgotPassword.otpSend,
        otpVerify: state.forgotPassword.otpVerify
    };
};

export default withRouter(connect(mapStateToProps, actions)(ForgotPassword));
