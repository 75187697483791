import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ReactGA from 'react-ga';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import MobileIcon from '@material-ui/icons/MobileFriendlyOutlined';

import withStyles from '@material-ui/core/styles/withStyles';
import loginStyle from '../../assets/jss/components/pages/loginStyle';

/**
 * Forgot password component
 */
class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            otpVerifForm: { otp: '' },
            mobileVerifyForm: { mobileNo: '' },
            forgotPasswordForm: {
                newPassword: '',
                confirmNewPassword: ''
            },
            passwordChanged:false,
            showPassword: false,
            showConfirmPassword: false
        }
    }

    gaEvent = (category, event, label) => {
        ReactGA.event({
            category: category,
            action: event,
            label: JSON.stringify(label)
        });
    }

    handleForgotPasswordChange = (event) => {
        const { forgotPasswordForm } = this.state;
        forgotPasswordForm[event.target.name] = event.target.value;
        this.setState({ forgotPasswordForm });
    }

    handleOtpVerifyChange = (event) => {
        const { otpVerifForm } = this.state;
        otpVerifForm[event.target.name] = event.target.value;
        this.setState({ otpVerifForm });
    }

    handleMobileVerifyChange = (event) => {
        const { mobileVerifyForm } = this.state;
        mobileVerifyForm[event.target.name] = event.target.value;
        this.setState({ mobileVerifyForm });
    }

    handleOtpSubmit = () => {
        this.gaEvent('User', 'Forgot Password-Submit OTP', this.state.mobileVerifyForm);
        this.props.verifyOtp(this.state.mobileVerifyForm.mobileNo, this.state.otpVerifForm.otp);
    }

    handleSendOtpSubmit = () => {
        this.gaEvent('User', 'Forgot Password-Send OTP', this.state.mobileVerifyForm);
        this.props.sendOtp(this.state.mobileVerifyForm.mobileNo);
    }

    handleForgotPasswordSubmit = () => {

        this.setState({ passwordChanged: true }, () => {
            setTimeout(() => this.setState({ passwordChanged: false }), 5000);
        });

        this.gaEvent('User', 'Forgot Password', this.state.mobileVerifyForm);
        let user = {};

        user.mobileNo = this.state.mobileVerifyForm.mobileNo;
        user.password = this.state.forgotPasswordForm.password;

        this.props.forgotPasswordAction(user);

     
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleClickShowConfirmPassword = () => {
        this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    };

    onCancel = () => {
        this.props.history.push('/login');
        this.props.resetForgotPasswordState();
    }

    componentWillMount() {

        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.forgotPasswordForm.password) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        this.props.resetForgotPasswordState();
    }

    render() {

        const { classes } = this.props;
        const { forgotPasswordForm, mobileVerifyForm, otpVerifForm, passwordChanged } = this.state;
        const { mobileVerify, otpSend, otpVerify} = this.props;

        return (
            <main className={classes.main}>
                <CssBaseline />

                {mobileVerify ?

                    <Slide direction="down" in={mobileVerify} mountOnEnter unmountOnExit>
                        <ValidatorForm
                                ref="form"
                                onSubmit={this.handleForgotPasswordSubmit}
                                className={classes.form}
                            >
                        <Paper className={classes.paper}>
                            
                                <Typography component="h1" style={{fontSize:'1.2em'}} >
                                    नवीन पासवर्ड भरा
                                </Typography>

                                <TextValidator
                                    label="पासवर्ड"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    onChange={this.handleForgotPasswordChange}
                                    name="password"
                                    value={forgotPasswordForm.password}
                                    validators={['required']}
                                    errorMessages={['पासवर्ड भरा']}
                                    fullWidth
                                    autoFocus={true}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />

                                <TextValidator
                                    label="पासवर्डची पुष्टी करा"
                                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                                    onChange={this.handleForgotPasswordChange}
                                    name="confirmPassword"
                                    value={forgotPasswordForm.confirmPassword}
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['पासवर्ड match होत नाही', 'पासवर्ड भरा']}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowConfirmPassword}
                                            >
                                                {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />

                                <Grid container
                                    direction="row"
                                    justify="center"
                                    alignItems="center">

                                    <Grid item>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            disabled={passwordChanged}
                                        >
                                            पासवर्ड change करा
                                        </Button>
                                    </Grid>
                                </Grid>
                            
                        </Paper>
                        </ValidatorForm>
                    </Slide> :

                    otpSend ?
                        <Slide direction="down" in={otpSend} mountOnEnter unmountOnExit>
                            <Paper className={classes.paper}>
                                <Typography component="h1" style={{fontSize:'1.2em'}} >
                                    One Time Password(OTP)
                                </Typography>

                                <Typography variant="caption">
                                    मोबाईल वरती SMS द्वारे पाठवलेला OTP टाका
                                </Typography>
                                <Typography variant="caption" style={{ color: 'red' }}>
                                    OTP वैध कालावधी १२० सेकंद
                                </Typography>

                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleOtpSubmit}
                                    className={classes.form}>

                                    <TextValidator
                                        label="Onetime Password(OTP)"
                                        onChange={this.handleOtpVerifyChange}
                                        fullWidth
                                        name="otp"
                                        value={otpVerifForm.otp}
                                        validators={['required']}
                                        errorMessages={['Onetime Password(OTP)']}
                                        autoFocus={true}
                                    />
                                    <Grid container
                                        direction="column"
                                        justify="center"
                                        alignItems="center">

                                        <Grid item>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.submit}
                                                style={{ textTransform: 'none' }}
                                            >
                                                Verify
                                            </Button>
                                        </Grid>

                                        <Grid item style={{ marginTop: '20px' }}> 
                                            <Typography variant="caption">
                                                OTP परत पाठविण्यासाठी येथे Click करा
                                            </Typography>

                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.submit}
                                                style={{ textTransform: 'none', marginTop: '0px' }}
                                                onClick={this.handleSendOtpSubmit}
                                            >
                                                Resend OTP
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </Paper>
                        </Slide>
                        :
                        !otpVerify && (<Slide direction="down" in={!otpSend} mountOnEnter unmountOnExit>
                            <Paper className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <MobileIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    पासवर्ड बदलण्यासाठी
                                </Typography>
                                <Typography variant="caption">
                                    आपण नोंद केलेला मोबाईल नंबर टाका
                                </Typography>
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSendOtpSubmit}
                                    className={classes.form}>

                                    <TextValidator
                                        label="मोबाईल नंबर"
                                        onChange={this.handleMobileVerifyChange}
                                        fullWidth
                                        name="mobileNo"
                                        type='number'
                                        value={mobileVerifyForm.mobileNo}
                                        validators={['required']}
                                        errorMessages={['आपला मोबाईल नंबर भरा']}
                                        autoFocus={true}
                                    />
                                    <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="center">
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.submit}
                                                style={{ textTransform: 'none' }}
                                            >
                                                Verify
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="caption" style={{ color: 'red', marginTop: '10px' }}>
                                        मोबाईल नंबर ची पडताळणी OTP द्वारे होईल.
                                    </Typography>
                                    <Typography variant="caption" style={{ color: 'red' }}>
                                        OTP आपणास त्वरित SMS द्वारे पाठवला जाईल
                                    </Typography>
                                </ValidatorForm>
                            </Paper>
                        </Slide>)}
            </main >
        );

    }
}

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(loginStyle)(Login);

export default withStyles(loginStyle, { withTheme: true })((ForgotPassword));
