import React from 'react';

import { Route } from 'react-router-dom';
import Header from './containers/header.container';

class App extends React.Component {

  render() {

    return (
      <React.Fragment>
        {/* <Route path='/' component={Header} /> */}
        <Header />
      </React.Fragment>
    );
  }
}

export default App